import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import '../../css/design4.css';
import {getlayoutRegistrationData} from "../../store/action/LayoutAction";
import  { commonFilter } from "../../utils/Common";

export default function Design4( { data } ) {
    const dispatch = useDispatch();
    const selector = useSelector(state =>state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modellayoutData) => {
        setModelData(modellayoutData)
        setShow(true);
    }
    useEffect(()=>{
            dispatch(getlayoutRegistrationData("SRCLAND000042"));
    },[]);
    useEffect(()=>{
        if(selector?.payload?.data?.message === "Success"){         
           setLayoutData(selector?.payload?.data?.data)
        }  
   },[selector?.payload?.data]) 

   const design4 = {
    layout1: [
        {
            itemno: 'item46',
            box_number: '46',
            boxsize_r: "158",
            boxsize_b: "87'6",
            boxsize_l: "161'-6''",
            status : layoutData.length > 0 ? commonFilter(layoutData, 13) : 0 ,
        },
        {
            itemno: 'item13',
            box_number: '13',
            boxsize_t: "35''",
            boxsize_l: "78'",
            status : layoutData.length > 0 ? commonFilter(layoutData, 13) : 0 ,
        },
        {
            itemno: 'item12',
            box_number: '12',
            boxsize_t: '35"',
            boxsize_l: "76'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 12) : 0 ,
        },     
        {
            itemno: 'item11',
            box_number: '11',
            boxsize_t: "35'",
            boxsize_l: "75'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 11) : 0 ,
        },
        {
            itemno: 'item10',
            box_number: '10',
            boxsize_t: "35'",
            boxsize_l: "74",
            status : layoutData.length > 0 ? commonFilter(layoutData, 10) : 0 ,
        },
        {
            itemno: 'item9',
            box_number: '9',
            boxsize_t: "35'",
            boxsize_l: "72",
            status : layoutData.length > 0 ? commonFilter(layoutData, 9) : 0 ,
        },
        {
            itemno: 'item8',
            box_number: '8',
            boxsize_t: "35'",
            boxsize_l: "71'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 8) : 0 ,
        },
        {
            itemno: 'item7',
            box_number: '7',
            boxsize_t: "35'",
            boxsize_l: "70",
            status : layoutData.length > 0 ? commonFilter(layoutData, 7) : 0 ,
        },
        {
            itemno: 'item6',
            box_number: '6',
            boxsize_t: "35",
            boxsize_l: "69",
            status : layoutData.length > 0 ? commonFilter(layoutData, 6) : 0 ,
        },
        {
            itemno: 'item5',
            box_number: '5',
            boxsize_t: "35",
            boxsize_l: "67'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 5) : 0 ,
        },
        {
            itemno: 'item4',
            box_number: '4',
            boxsize_t: "35",
            boxsize_l: "66",
            status : layoutData.length > 0 ? commonFilter(layoutData, 4) : 0 ,
        },
        {
            itemno: 'item3',
            box_number: '3',
            boxsize_t: "35",
            boxsize_l: "64'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 3) : 0 ,
        },
        {
            itemno: 'item2',
            box_number: '2',
            boxsize_t: "35",
            boxsize_l: "62'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 2) : 0 ,
        },
        {
            itemno: 'item1',
            box_number: '1',
            boxsize_t: "48",
            boxsize_b: "60",
            boxsize_l: "61'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 1) : 0 ,
        }
    ],
    layout2: [
        {
            itemno: 'item14',
            box_number: '14',
            status : layoutData.length > 0 ? commonFilter(layoutData, 14) : 0 ,
        },
        {
            itemno: 'item15',
            box_number: '15',
            status : layoutData.length > 0 ? commonFilter(layoutData, 15) : 0 ,
        },
    ],
    layout2A: [
        {
            itemno: 'item16',
            box_number: '16',
            boxsize_r: "30",
            status : layoutData.length > 0 ? commonFilter(layoutData, 16) : 0 ,
        },
        {
            itemno: 'item17',
            box_number: '17',
            boxsize_r: "25",
            boxsize_b: "67",
            status : layoutData.length > 0 ? commonFilter(layoutData, 17) : 0 ,
        },
    ],
    layout3: [
        {
            itemno: 'item18',
            box_number: '18',
            boxsize_b: "35",
            boxsize_l: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 18) : 0 ,
        },
        {
            itemno: 'item19',
            box_number: '19',
            boxsize_b: "40'",
            status : layoutData.length > 0 ? commonFilter(layoutData, 19) : 0 ,
        },
        {
            itemno: 'item20',
            box_number: '20',
            boxsize_b: "40",
            status : layoutData.length > 0 ? commonFilter(layoutData, 20) : 0 ,
        },
        {
            itemno: 'item21',
            box_number: '21',
            boxsize_b: "40",
            status : layoutData.length > 0 ? commonFilter(layoutData, 21) : 0 ,
        },
        {
            itemno: 'item22',
            box_number: '22',
            boxsize_b: "40",
            status : layoutData.length > 0 ? commonFilter(layoutData, 22) : 0 ,
        },
        {
            itemno: 'item23',
            box_number: '23',
            boxsize_b: "20",
            status : layoutData.length > 0 ? commonFilter(layoutData, 23) : 0 ,
        },
        {
            itemno: 'item45',
            box_number: '45',
            boxsize_b: "20",
            status : layoutData.length > 0 ? commonFilter(layoutData, 23) : 0 ,
        },
        {
            itemno: 'item24',
            box_number: '24',
            boxsize_b: "42'6",
            boxsize_l: "60",
            status : layoutData.length > 0 ? commonFilter(layoutData, 24) : 0 ,
        },
        {
            itemno: 'item25',
            box_number: '25',
            boxsize_t: "66",
            boxsize_r: "33",
            boxsize_b: "62",
            boxsize_l: "35",
            status : layoutData.length > 0 ? commonFilter(layoutData, 25) : 0 ,
        },

    ],
    layout4: [
        {
            itemno: 'item26',
            box_number: '26',
            boxsize_t: "60",
            boxsize_b: "55",
            boxsize_l: "33'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 26) : 0 ,
        },
        {
            itemno: 'item27',
            box_number: '27',
            boxsize_l: "38'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 27) : 0 ,
        },
        {
            itemno: 'item28',
            box_number: '28',
            status : layoutData.length > 0 ? commonFilter(layoutData, 28) : 0 ,
        },
        {
            itemno: 'item29',
            box_number: '29',
            status : layoutData.length > 0 ? commonFilter(layoutData, 29) : 0 ,
        },
    ],
    layout5: [
        {
            itemno: 'item30',
            box_number: '33',
            boxsize_t: "50",
            boxsize_r: "45",
            boxsize_b: "55",
            boxsize_l: "40",
            status : layoutData.length > 0 ? commonFilter(layoutData, 30) : 0 ,
        },
        {
            itemno: 'item31',
            box_number: '32',
            boxsize_t: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 31) : 0 ,
        },
        {
            itemno: 'item32',
            box_number: '31',
            boxsize_t: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 32) : 0 ,
        },
        {
            itemno: 'item33',
            box_number: '30',
            boxsize_t: "59'6",
            boxsize_b: "58'9",
            status : layoutData.length > 0 ? commonFilter(layoutData, 33) : 0 ,
        },
    ],
    layout5A: [
        {
            itemno: 'item34',
            box_number: '34',
            boxsize_r: "45",
            boxsize_b: "50",
            boxsize_l: "40",
            status : layoutData.length > 0 ? commonFilter(layoutData, 34) : 0 ,
        },
        {
            itemno: 'item35',
            box_number: '35',
            boxsize_b: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 35) : 0 ,
        },
        {
            itemno: 'item36',
            box_number: '36',
            boxsize_b: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 36) : 0 ,
        },
        {
            itemno: 'item37',
            box_number: '37',
            boxsize_b: "58",
            status : layoutData.length > 0 ? commonFilter(layoutData, 37) : 0 ,
        },
    ],
    layout6: [
        {
            itemno: 'item38',
            box_number: '38',
            boxsize_t: "55",
            boxsize_b: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 38) : 0 ,
        },
        {
            itemno: 'item39',
            box_number: '39',
            boxsize_t: "55",
            boxsize_b: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 39) : 0 ,
        },
        {
            itemno: 'item40',
            box_number: '40',
            boxsize_t: "55",
            boxsize_b: "55",
            status : layoutData.length > 0 ? commonFilter(layoutData, 40) : 0 ,
        },
        {
            itemno: 'item41',
            box_number: '41',
            boxsize_t: "50",
            boxsize_b: "55",
            boxsize_l: "39'3",
            status : layoutData.length > 0 ? commonFilter(layoutData, 41) : 0 ,
        },
    ],
    layout7: [
        {
            itemno: 'item42',
            box_number: '44',
            boxsize_r: "49",
            boxsize_b: "54",
            boxsize_l: "41'6",
            status : layoutData.length > 0 ? commonFilter(layoutData, 42) : 0 ,
        },
        {
            itemno: 'item43',
            box_number: '43',
            boxsize_r: "60",
            boxsize_b: "49'6'",
            boxsize_l: "60",
            status : layoutData.length > 0 ? commonFilter(layoutData, 43) : 0 ,
        },
        {
            itemno: 'item44',
            box_number: '42',
            boxsize_r: "54",
            boxsize_b: "46'6",
            boxsize_l: "57",
            status : layoutData.length > 0 ? commonFilter(layoutData, 44) : 0 ,
        },

    ],
    layout8: [
        {
            itemno: 'item26A',                              
        },
        {
            itemno: 'item26B',                              
        },
    ],
    layout9: [
        {
            itemno: 'item41A',                             
        },
        {
            itemno: 'item42A',                             
        },
        {
            itemno: 'item43A',                             
        },
        {
            itemno: 'item44A',                             
        },
    ]


}

return (
    <>
        {
            data == "true" && <Modal
            show={show}
            onHide={handleClose}
            backdrop="true"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Land Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Site Identification No: {modelData?.box_number} <br />
                Site Size Details:{modelData?.boxsize_t} {modelData?.boxsize_r} {modelData?.boxsize_b} {modelData?.boxsize_l}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        }
        <div className="container">
            <div className="">
                <div className='design-4'>
                    <div className='main-layout'>
                        <div className='layout-1'>
                            {
                                design4.layout1.map((data, index) => {
                                    return (<>
                                        <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }
                        </div>
                        <div className='layout-2'>
                            {
                                design4.layout2.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-2A'>
                            {
                                design4.layout2A.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-3'>
                            {
                                design4.layout3.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-4'>
                            {
                                design4.layout4.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-5'>
                            {
                                design4.layout5.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-5A'>
                            {
                                design4.layout5A.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-6'>
                            {
                                design4.layout6.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-7'>
                            {
                                design4.layout7.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-8'>
                            {
                                design4.layout8.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>
                        <div className='layout-9'>
                            {
                                design4.layout9.map((data, index) => {
                                    return (<>
                                       <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                            <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                            <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                            <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                        </button>
                                    </>
                                    )
                                })
                            }

                        </div>

                        <div class="layout-10">GIRI</div>	
                        <div class="layout-11">NAGAR</div>	
                        <div class="layout-12">LAYOUT</div>	

                        <div class="road-1">20' ROAD </div>
                        <div class="road-2">20' ROAD </div>
                        <div class="road-3">20' ROAD </div>
                        <div class="road-4">30' ROAD </div>
                        <div class="road-5">30' ROAD </div>
                        <div class="road-6">FROM SARAVANAMPATTY TO KEERANATHAM MAIN ROAD</div>
                        <div class="road-7"></div>
                        <div class="road-8"></div>
                        <div class="road-9"></div>


                        <div class="whitebox1"></div>
                        <div class="whitebox2"></div>
                        <div class="whitebox3"></div>
                        <div class="whitebox4"></div>
                        <div class="whitebox5"></div>
                        <div class="whitebox6"></div>
                        <div class="whitebox7"></div>
                        <div class="whitebox8"></div>


                        <div class="roadcross41"></div>
                        <div class="roadcross34"></div>
                        <div class="roadcross33"></div>
                        <div class="roadcross26"></div>
                        <div class="roadcross25"></div>
                        <div class="roadcross44"></div>
                        <div class="roadcross17"></div>
                        <div class="roadcross18"></div>
                        <div class="roadcross1"></div>
                        <div class="roadcross2"></div>
                        <div class="roadcross3"></div>
                        <div class="roadcross4"></div>
                        <div class="roadcross5"></div>
                        <div class="roadcross6"></div>
                        
                        <div class="sf1">S.F.NO.340/3 Part</div>
                        <div class="sf2">S.F.NO.340/4 Part</div>
                    </div>
                </div>
            </div>
        </div>

    </>
)
}

