import axios from 'axios';
import { BIND_SUCCESS, BIND_FAIL, STAFF_INSERT , STAFF_UPDATE, STAFF_DELETE } from  "../type/types";
import { Url, getTokenLocalStorage } from '../../utils/Common';
import swal from 'sweetalert';

// Otp Verification
export const getBindData = (actionData) => async dispatch =>{
    const token  = getTokenLocalStorage();
    const formData = {
        "action" : "View",
        "type" : actionData
    }  
    const headers = {"x-auth-header" : `${token}` }         
    axios.post(`${Url}bind`,formData , { headers } ).then((response)=>{     
        dispatch({
            payload : response,
            type : BIND_SUCCESS
        })      
    }).catch((err)=>{                  
        dispatch({
            payload : err.response,
            type : BIND_FAIL
        })
    })   
}
