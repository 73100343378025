import axios from 'axios';
import { LAYOUT_VIEW, LAYOUT_INSERT, LAYOUT_SUCCESS, LAYOUT_FAIL, LAYOUT_UPDATE, LAYOUT_DELETE } from  "../type/types";
import { Url, getTokenLocalStorage } from '../../utils/Common';

// Get Layout Roll Number
export const getlayoutRegistrationData = (layoutRollNo) => async dispatch =>{
    const token  = getTokenLocalStorage();
    const formData = {
        "action" :"View",
        "layoutRollNo" : layoutRollNo,
        "order" : "asc"
    }
    const headers = {"x-auth-header" : `${token}` }         
    axios.post(`${Url}site`,formData , { headers } ).then((response)=>{     
        dispatch({
            payload : response,
            type : LAYOUT_VIEW
        })      
    }).catch((err)=>{                  
        dispatch({
            payload : err.response,
            type : LAYOUT_FAIL
        })
    })   
}
