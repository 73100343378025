import React from 'react'
import Design4 from '../Layout/Design4'

import CarouselProperty from './CarouselProperty'

export default function SiteDetails() {
    return (
        <div className="container ">
            <div className="row ">
                <div className="col-sm-12 d-flex ">
                    <div className="style-box"></div>
                    <p className='Featured-listing'>Featured Listing of the Week</p>
                </div>
            </div>
            <div className="row mb-lg-5">
                <CarouselProperty />
                {/* <Design4 /> */}

            </div>
            <div className="row mb-lg-4">
                <div className="col-lg-9 col-md-12 site-details-info">
                    <div className="row text-left pl-3">
                        <div className="col">
                            <h6>Budget</h6>
                            <p>Confidential</p>
                        </div>
                        <div className="col">
                            <h6>Size</h6>
                            <p>1200 <span>&#13217;</span></p>
                        </div>
                        <div className="col">
                            <h6>Type</h6>
                            <p>Office</p>
                        </div>
                        <div className="col">
                            <h6>Status</h6>
                            <p>Done</p>
                        </div>
                        <div className="col">
                            <h6>Location</h6>
                            <p>Anthiyur</p>
                        </div>
                        <div className="col">
                            <h6>Flat</h6>
                            <p>8 Room</p>
                        </div>
                    </div>

                </div>
                <div className="col-lg-3  col-md-12 col-sm-12 mb-sm-3 mb-md-5 pr-md-3">
                    <button className='btn btn-book text-white mx-auto d-block'>YES! I WANT TO BOOK</button>
                </div>
            </div>

        </div>
    )
}
