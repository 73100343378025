import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import '../../css/design8.css';
import { useEffect } from 'react';
import {getlayoutRegistrationData} from "../../store/action/LayoutAction";
import  { commonFilter } from "../../utils/Common";


export default function Design8( { data } ) {
    const dispatch = useDispatch();
    const selector = useSelector(state =>state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modellayoutData) => {
        setModelData(modellayoutData)
        setShow(true);
    }
    useEffect(()=>{
            dispatch(getlayoutRegistrationData("SRCLAND000067"));
    },[]);
    useEffect(()=>{
        if(selector?.payload?.data?.message === "Success"){         
           setLayoutData(selector?.payload?.data?.data)
        }  
   },[selector?.payload?.data])      

    const design8 = {
        layout1: [
            {
                itemno: 'item1',
                box_number: 1,
                boxsize_t: '21.3',
                boxsize_r: '50.9',
                boxsize_b: '30.3',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 1) : 0 ,
            },
            {
                itemno: 'item2',
                box_number: 2,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 2) : 0 ,
            },
            {
                itemno: 'item3',
                box_number: 3,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 3) : 0 ,
            },
            {
                itemno: 'item4',
                box_number: 4,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 4) : 0 ,
            },
            {
                itemno: 'item5',
                box_number: 5,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 5) : 0 ,
            },
            {
                itemno: 'item6',
                box_number: 6,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 6) : 0 ,
            },
            {
                itemno: 'item7',
                box_number: 7,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 7) : 0 ,
            },
            {
                itemno: 'item8',
                box_number: 8,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '25',
                boxsize_l: '45',
                status : layoutData.length > 0 ? commonFilter(layoutData, 8) : 0 ,
            },
        ],
        layout2: [
            {
                itemno: 'item9',
                box_number: 9,
                boxsize_t: '35',
                boxsize_r: '45',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 9) : 0 ,
            },
            {
                itemno: 'item10',
                box_number: 10,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 10) : 0 ,
            },
            {
                itemno: 'item11',
                box_number: 11,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 11) : 0 ,
            },
            {
                itemno: 'item12',
                box_number: 12,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 12) : 0 ,
            },
            {
                itemno: 'item13',
                box_number: 13,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 13) : 0 ,
            },
            {
                itemno: 'item14',
                box_number: 14,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 14) : 0 ,
            },
            {
                itemno: 'item15',
                box_number: 15,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 15) : 0 ,
            },
            {
                itemno: 'item16',
                box_number: 16,
                boxsize_t: '36',
                boxsize_r: '50',
                boxsize_b: '41.6',
                boxsize_l: '46.3',
                status : layoutData.length > 0 ? commonFilter(layoutData, 16) : 0 ,
            },
        ],
        layout3: [
            {
                itemno: 'item17',
                box_number: 17,
                boxsize_t: '32.9',
                boxsize_r: '50',
                boxsize_b: '48',
                boxsize_l: '46.3',
                status : layoutData.length > 0 ? commonFilter(layoutData, 17) : 0 ,
            },
            {
                itemno: 'item18',
                box_number: 18,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '48',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 18) : 0 ,
            },
            {
                itemno: 'item19',
                box_number: 19,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '48',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 19) : 0 ,
            },
            {
                itemno: 'item20',
                box_number: 20,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '48',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 20) : 0 ,
            },
            {
                itemno: 'item21',
                box_number: 21,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '48',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 21) : 0 ,
            },
            {
                itemno: 'item22',
                box_number: 22,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '48',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 22) : 0 ,
            },
            {
                itemno: 'item23',
                box_number: 23,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '48',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 23) : 0 ,
            },
        ],
        layout4: [
            {
                itemno: 'item24',
                box_number: 24,
                boxsize_t: '45',
                boxsize_r: '25',
                boxsize_b: '50',
                boxsize_l: '30',
                status : layoutData.length > 0 ? commonFilter(layoutData, 24) : 0 ,
            },
            {
                itemno: 'item25',
                box_number: 25,
                boxsize_t: '30',
                boxsize_r: '30',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 25) : 0 ,
            },
            {
                itemno: 'item42',
                box_number: 42,
                boxsize_t: '',
                boxsize_r: '28.6',
                boxsize_b: '45',
                boxsize_l: '33.6',
                status : layoutData.length > 0 ? commonFilter(layoutData, 42) : 0 ,
            },
        ],
        layout5: [
            {
                itemno: 'item26',
                box_number: 26,
                boxsize_t: '25',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '45',
                status : layoutData.length > 0 ? commonFilter(layoutData, 26) : 0 ,
            },
            {
                itemno: 'item27',
                box_number: 27,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 27) : 0 ,
            },
            {
                itemno: 'item28',
                box_number: 28,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 28) : 0 ,
            },
            {
                itemno: 'item29',
                box_number: 29,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 29) : 0 ,
            },
            {
                itemno: 'item30',
                box_number: 30,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 30) : 0 ,
            },
            {
                itemno: 'item31',
                box_number: 31,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 31) : 0 ,
            },
            {
                itemno: 'item32',
                box_number: 32,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 32) : 0 ,
            },
            {
                itemno: 'item33',
                box_number: 33,
                boxsize_t: '35.9',
                boxsize_r: '50.9',
                boxsize_b: '44.9',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 33) : 0 ,
            },
        ],
        layout6: [
            {
                itemno: 'item34',
                box_number: 34,
                boxsize_t: '',
                boxsize_r: '44.3',
                boxsize_b: '52.6',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 34) : 0 ,
            },
            {
                itemno: 'item35',
                box_number: 35,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 35) : 0 ,
            },
            {
                itemno: 'item36',
                box_number: 36,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 35) : 0 ,
            },
            {
                itemno: 'item37',
                box_number: 37,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 37) : 0 ,
            },
            {
                itemno: 'item38',
                box_number: 38,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 38) : 0 ,
            },
            {
                itemno: 'item39',
                box_number: 39,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 39) : 0 ,
            },
            {
                itemno: 'item40',
                box_number: 40,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 40) : 0 ,
            },
            {
                itemno: 'item41',
                box_number: 41,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '25',
                boxsize_l: '38.6',
                status : layoutData.length > 0 ? commonFilter(layoutData, 41) : 0 ,
            },
        ],
        layout7: [
            {
                itemno: 'item43',
                box_number: 43,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 43) : 0 ,
            },
            {
                itemno: 'item44',
                box_number: 44,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 44) : 0 ,
            },
            {
                itemno: 'item45',
                box_number: 45,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 45) : 0 ,
            },
            {
                itemno: 'item46',
                box_number: 46,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 46) : 0 ,
            },
            {
                itemno: 'item47',
                box_number: 47,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 47) : 0 ,
            },
            {
                itemno: 'item48',
                box_number: 48,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 48) : 0 ,
            },
            {
                itemno: 'item49',
                box_number: 49,
                boxsize_t: '',
                boxsize_r: '43.6',
                boxsize_b: '52',
                boxsize_l: '39.6',
                status : layoutData.length > 0 ? commonFilter(layoutData, 49) : 0 ,
            },
        ],
        layout8: [
            {
                itemno: 'item50',
                box_number: 50,
                boxsize_t: '27.6',
                boxsize_r: '50',
                boxsize_b: '43.6',
                boxsize_l: '46.3',
                status : layoutData.length > 0 ? commonFilter(layoutData, 50) : 0 ,
            },
            {
                itemno: 'item51',
                box_number: 51,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 51) : 0 ,
            },
            {
                itemno: 'item52',
                box_number: 52,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 52) : 0 ,
            },
            {
                itemno: 'item53',
                box_number: 53,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 53) : 0 ,
            },
            {
                itemno: 'item54',
                box_number: 54,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 54) : 0 ,
            },
            {
                itemno: 'item55',
                box_number: 55,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 55) : 0 ,
            },
            {
                itemno: 'item56',
                box_number: 56,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 56) : 0 ,
            },
            {
                itemno: 'item57',
                box_number: 57,
                boxsize_t: '30',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 57) : 0 ,
            },
        ],
        layout9: [
            {
                itemno: 'item58',
                box_number: 58,
                boxsize_t: '45',
                boxsize_r: '25',
                boxsize_b: '50',
                boxsize_l: '30',
                status : layoutData.length > 0 ? commonFilter(layoutData, 58) : 0 ,
            },
            {
                itemno: 'item59',
                box_number: 59,
                boxsize_t: '',
                boxsize_r: '30',
                boxsize_b: '50',
                boxsize_l: '30',
                status : layoutData.length > 0 ? commonFilter(layoutData, 59) : 0 ,
            },
            {
                itemno: 'item85',
                box_number: 85,
                boxsize_t: '',
                boxsize_r: '25',
                boxsize_b: '45',
                boxsize_l: '30',
                status : layoutData.length > 0 ? commonFilter(layoutData, 85) : 0 ,
            },
        ],
        layout10: [
            {
                itemno: 'item60',
                box_number: 60,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 60) : 0 ,
            },
            {
                itemno: 'item61',
                box_number: 61,
                boxsize_t: '25.3',
                boxsize_r: '50',
                boxsize_b: '30',
                boxsize_l: '45',
                status : layoutData.length > 0 ? commonFilter(layoutData, 61) : 0 ,
            },
            {
                itemno: 'item62',
                box_number: 62,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 62) : 0 ,
            },
            {
                itemno: 'item63',
                box_number: 63,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 63) : 0 ,
            },
            {
                itemno: 'item64',
                box_number: 64,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 64) : 0 ,
            },
            {
                itemno: 'item65',
                box_number: 65,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 65) : 0 ,
            },
            {
                itemno: 'item66',
                box_number: 66,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 66) : 0 ,
            },
            {
                itemno: 'item67',
                box_number: 67,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 67) : 0 ,
            },
            {
                itemno: 'item68',
                box_number: 68,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 68) : 0 ,
            },
        ],
        layout11: [
            {
                itemno: 'item69',
                box_number: 69,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 69) : 0 ,
            },
            {
                itemno: 'item70',
                box_number: 70,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 70) : 0 ,
            },
            {
                itemno: 'item71',
                box_number: 71,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 71) : 0 ,
            },
            {
                itemno: 'item72',
                box_number: 72,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 72) : 0 ,
            },
            {
                itemno: 'item73',
                box_number: 73,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 73) : 0 ,
            },
            {
                itemno: 'item74',
                box_number: 74,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 74) : 0 ,
            },
            {
                itemno: 'item75',
                box_number: 75,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 75) : 0 ,
            },
            {
                itemno: 'item76',
                box_number: 76,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 76) : 0 ,
            },
            {
                itemno: 'item77',
                box_number: 77,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 77) : 0 ,
            },
            {
                itemno: 'item78',
                box_number: 78,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 78) : 0 ,
            },
            {
                itemno: 'item79',
                box_number: 79,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 79) : 0 ,
            },
            {
                itemno: 'item80',
                box_number: 80,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 80) : 0 ,
            },            
            {
                itemno: 'item81',
                box_number: 81,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 81) : 0 ,
            },
            {
                itemno: 'item82',
                box_number: 82,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 82) : 0 ,
            },
            {
                itemno: 'item83',
                box_number: 83,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 83) : 0 ,
            },
            {
                itemno: 'item84',
                box_number: 84,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 84) : 0 ,
            },
        ],
        layout12: [
            {
                itemno: 'item86',
                box_number: 86,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 86) : 0 ,
            },
            {
                itemno: 'item87',
                box_number: 87,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 87) : 0 ,
            },
            {
                itemno: 'item88',
                box_number: 88,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 88) : 0 ,
            },
            {
                itemno: 'item89',
                box_number: 89,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 89) : 0 ,
            },
            {
                itemno: 'item90',
                box_number: 90,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 90) : 0 ,
            },
            {
                itemno: 'item91',
                box_number: 91,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 91) : 0 ,
            },
            {
                itemno: 'item92',
                box_number: 92,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 92) : 0 ,
            },
            {
                itemno: 'item93',
                box_number: 93,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 93) : 0 ,
            },
        ],  
        layout13: [
            {
                itemno: 'item94',
                box_number: 94,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 94) : 0 ,
            },
            {
                itemno: 'item95',
                box_number: 95,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 95) : 0 ,
            },
            {
                itemno: 'item96',
                box_number: 96,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 96) : 0 ,
            },
            {
                itemno: 'item97',
                box_number: 97,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 97) : 0 ,
            },
            {
                itemno: 'item98',
                box_number: 98,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 98) : 0 ,
            },
            {
                itemno: 'item99',
                box_number: 99,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 99) : 0 ,
            },
        ],  
        layout14: [
            {
                itemno: 'item100',
                box_number: 100,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 100) : 0 ,
            },    
            {
                itemno: 'item101',
                box_number: 101,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 101) : 0 ,
            },    
            {
                itemno: 'item102',
                box_number: 102,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 102) : 0 ,
            },    
            {
                itemno: 'item103',
                box_number: 103,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 103) : 0 ,
            },    
            {
                itemno: 'item104',
                box_number: 104,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 104) : 0 ,
            },    
            {
                itemno: 'item105',
                box_number: 105,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 105) : 0 ,
            },    
            {
                itemno: 'item106',
                box_number: 106,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 106) : 0 ,
            },    
            {
                itemno: 'item107',
                box_number: 107,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 107) : 0 ,
            },    
            {
                itemno: 'item108',
                box_number: 108,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6',
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 108) : 0 ,
            },    
            {
                itemno: 'item109',
                box_number: 109,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6',
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 109) : 0 ,
            },    
        ],  
        layout15: [
            {
                itemno: 'item110',
                box_number: 110,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 110) : 0 ,
            }, 
            {
                itemno: 'item111',
                box_number: 111,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 111) : 0 ,
            }, 
            {
                itemno: 'item112',
                box_number: 112,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 112) : 0 ,
            }, 
            {
                itemno: 'item113',
                box_number: 113,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 113) : 0 ,
            }, 
            {
                itemno: 'item114',
                box_number: 114,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 114) : 0 ,
            }, 
            {
                itemno: 'item115',
                box_number: 115,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 115) : 0 ,
            }, 
            {
                itemno: 'item116',
                box_number: 116,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 116) : 0 ,
            }, 
            {
                itemno: 'item117',
                box_number: 117,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 117) : 0 ,
            }, 
            {
                itemno: 'item118',
                box_number: 118,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 118) : 0 ,
            }, 
            {
                itemno: 'item119',
                box_number: 119,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 119) : 0 ,
            }, 
            {
                itemno: 'item120',
                box_number: 120,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 120) : 0 ,
            }, 
            {
                itemno: 'item121',
                box_number: 121,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 121) : 0 ,
            }, 
            {
                itemno: 'item122',
                box_number: 122,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 122) : 0 ,
            }, 
            {
                itemno: 'item123',
                box_number: 123,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 123) : 0 ,
            }, 
            {
                itemno: 'item124',
                box_number: 124,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 124) : 0 ,
            }, 
            {
                itemno: 'item125',
                box_number: 125,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 125) : 0 ,
            }, 
            {
                itemno: 'item126',
                box_number: 126,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 126) : 0 ,
            }, 
            {
                itemno: 'item127',
                box_number: 127,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 127) : 0 ,
            }, 
        ],  
        layout16: [
            {
                itemno: 'item128',
                box_number: 128,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 128) : 0 ,
            }, 
            {
                itemno: 'item129',
                box_number: 129,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 129) : 0 ,
            }, 
            {
                itemno: 'item130',
                box_number: 130,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 130) : 0 ,
            }, 
            {
                itemno: 'item131',
                box_number: 131,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 131) : 0 ,
            }, 
            {
                itemno: 'item132',
                box_number: 132,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 132) : 0 ,
            }, 
            {
                itemno: 'item133',
                box_number: 133,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 133) : 0 ,
            }, 
            {
                itemno: 'item134',
                box_number: 134,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 134) : 0 ,
            }, 
            {
                itemno: 'item135',
                box_number: 135,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 135) : 0 ,
            }, 
            {
                itemno: 'item136',
                box_number: 136,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 136) : 0 ,
            }, 
            {
                itemno: 'item137',
                box_number: 137,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 137) : 0 ,
            },             
        ],
        layout17: [
            {
                itemno: 'item138',
                box_number: 138,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 138) : 0 ,
            }, 
            {
                itemno: 'item139',
                box_number: 139,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 139) : 0 ,
            },
            {
                itemno: 'item140',
                box_number: 140,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 140) : 0 ,
            },
            {
                itemno: 'item141',
                box_number: 141,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 141) : 0 ,
            },
            {
                itemno: 'item142',
                box_number: 142,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 142) : 0 ,
            },
            {
                itemno: 'item143',
                box_number: 143,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 143) : 0 ,
            },
            {
                itemno: 'item144',
                box_number: 144,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 144) : 0 ,
            },
            {
                itemno: 'item145',
                box_number: 145,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 145) : 0 ,
            },
            {
                itemno: 'item146',
                box_number: 146,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 146) : 0 ,
            },
            {
                itemno: 'item147',
                box_number: 147,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 147) : 0 ,
            },
            {
                itemno: 'item148',
                box_number: 148,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 148) : 0 ,
            },
            {
                itemno: 'item149',
                box_number: 149,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 149) : 0 ,
            },
            {
                itemno: 'item150',
                box_number: 150,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 150) : 0 ,
            },
            {
                itemno: 'item151',
                box_number: 151,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 151) : 0 ,
            },
            {
                itemno: 'item152',
                box_number: 152,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 132) : 0 ,
            },
            {
                itemno: 'item153',
                box_number: 153,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 153) : 0 ,
            },
            {
                itemno: 'item154',
                box_number: 154,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 154) : 0 ,
            },
            {
                itemno: 'item155',
                box_number: 155,
                boxsize_t: '',
                boxsize_r: '54',
                boxsize_b: '46.6', 
                boxsize_l: '57',
                status : layoutData.length > 0 ? commonFilter(layoutData, 155) : 0 ,
            },
        ],
        layout18: [
            {
                itemno: 'item156',
                box_number: 156,
                boxsize_t: '66',
                boxsize_r: '33',
                boxsize_b: '62', 
                boxsize_l: '38',
                status : layoutData.length > 0 ? commonFilter(layoutData, 156) : 0 ,
            },
            {
                itemno: 'item157',
                box_number: 157,
                boxsize_t: '66',
                boxsize_r: '33',
                boxsize_b: '62', 
                boxsize_l: '38',
                status : layoutData.length > 0 ? commonFilter(layoutData, 157) : 0 ,
            },
        ],
        layout19: [
            {
                itemno: 'item158',
                box_number: 158,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 158) : 0 ,
            },
            {
                itemno: 'item159',
                box_number: 159,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 159) : 0 ,
            },
            {
                itemno: 'item160',
                box_number: 160,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 160) : 0 ,
            },
            {
                itemno: 'item161',
                box_number: 161,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 161) : 0 ,
            },
            {
                itemno: 'item162',
                box_number: 162,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 162) : 0 ,
            },
        ],
        layout20: [
            {
                itemno: 'item163',
                box_number: 163,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 163) : 0 ,
            }, 
            {
                itemno: 'item164',
                box_number: 164,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 164) : 0 ,
            }, 
            {
                itemno: 'item165',
                box_number: 165,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 165) : 0 ,
            }, 
            {
                itemno: 'item166',
                box_number: 166,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 166) : 0 ,
            }, 
        ],
        layout21: [
            {
                itemno: 'item167',
                box_number: 167,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 167) : 0 ,
            },
            {
                itemno: 'item168',
                box_number: 168,
                boxsize_t: '',
                boxsize_r: '60',
                boxsize_b: '49.6', 
                boxsize_l: '60',
                status : layoutData.length > 0 ? commonFilter(layoutData, 168) : 0 ,
            },
        ],
        layout22: [
            {
                itemno: 'item169',                
            },
            {
                itemno: 'item170',                
            },
        ],
    }

    return(
        <>
             {
                data === "true" && <Modal
                show={show}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
        >   
            <Modal.Header closeButton>
                <Modal.Title>Land Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Site Identification No: {modelData?.box_number} <br />
                Site Size Details:{modelData?.boxsize_t} {modelData?.boxsize_r} {modelData?.boxsize_b} {modelData?.boxsize_l}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </Modal>
            }    

                 <div className="container">
                <div className="">
                    <div className='design-8'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design8.layout1.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }                               
                            </div>  
                            <div className='layout-2'>
                                {
                                    design8.layout2.map((data, index) => {
                                        return (<>
                                          <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-3'>
                                {
                                    design8.layout3.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-4'>
                                {
                                    design8.layout4.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-5'>
                                {
                                    design8.layout5.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }                                
                            </div>  
                            <div className='layout-6'>
                                {
                                    design8.layout6.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-7'>
                                {
                                    design8.layout7.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-8'>
                                {
                                    design8.layout8.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-9'>
                                {
                                    design8.layout9.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-10'>
                                {
                                    design8.layout10.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-11'>
                                {
                                    design8.layout11.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-12'>
                                {
                                    design8.layout12.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }                                
                            </div>  
                            <div className='layout-13'>
                                {
                                    design8.layout13.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-14'>
                                {
                                    design8.layout14.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-15'>
                                {
                                    design8.layout15.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-16'>
                                {
                                    design8.layout16.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-17'>
                                {
                                    design8.layout17.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-18'>
                                {
                                    design8.layout18.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-19'>
                                {
                                    design8.layout19.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-20'>
                                {
                                    design8.layout20.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-21'>
                                {
                                    design8.layout21.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-22'>
                                {
                                    design8.layout22.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                                               

                            <div class="road-1">10 M layout Road </div>
                            <div class="road-2">9.0 M layout Road </div>
                            <div class="road-3">9.0 M layout Road </div>
                            <div class="road-4">9.0 M layout Road </div>
                            <div class="road-5">9.0 M layout Road </div>
                            <div class="road-6">9.0 M layout Road </div>
                            <div class="road-7">9.0 M layout Road </div>
                            <div class="road-8">7.2 M layout Road </div>
                            <div class="road-9">7.2 M layout Road </div>
                            <div class="road-10">9.0 M layout Road </div>
                            <div class="road-11">9.0 M layout Road </div>
                            <div class="road-12">9.0 M layout Road </div>
                            <div class="road-13">9.0 M layout Road </div>
                            <div class="road-14">9.0 M layout Road </div>
                            <div class="road-15">9.0 M layout Road </div>
                            
                            

                            <div class="whitebox1"></div>
                            <div class="whitebox2"></div>
                            <div class="whitebox3"></div>
                            <div class="whitebox4"></div>
                            <div class="whitebox5"></div>
                            <div class="whitebox6"></div>
                            <div class="whitebox7"></div>
                            <div class="whitebox8"></div>
                            

                            <div class="sf1">PARK</div>                           
                                                  
                            <div class="sm1">K.S. No.330</div>                           
                            <div class="sm2">K.S. No.331/1</div>                           
                            <div class="sm3">K.S. No.331/2B</div>                           
                            <div class="sm4">K.S. No.414</div>                           
                            <div class="sm5">K.S. No.332</div>                           
                              


                            <div class="rs1">177'</div>                           
                            <div class="rs2">209'-9'</div>                           
                            <div class="rs3">241'-9'</div>                           
                            <div class="rs4">TANGEDCO</div>                           
                            <div class="rs5">GENERAL</div>                           
                                                     
                        </div>
                    </div>
                </div>
            </div>    
        </>
    )
}