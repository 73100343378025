import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Usericon from '../../images/header/Male_User.png';


class Header extends Component {
	state = {
		// initial state
		show: false,
	}

	handleClose = () => {
		this.setState({ show: false });
	};
	handleShow = () => {
		this.setState({ show: true });
	};
	componentDidMount() {
		// sidebar open/close

		var Navicon = document.querySelector('.navicon');
		var sidebarmenu = document.querySelector('.myNavbar ');

		function toggleFunc() {
			sidebarmenu.classList.toggle('show');
			//   Navicon.classList.toggle('open');
		}
		Navicon.addEventListener('click', toggleFunc);

		// Sidenav li open close
		var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a'));
		for (var y = 0; y < navUl.length; y++) {
			navUl[y].addEventListener('click', function () { checkLi(this) });
		}

		function checkLi(current) {
			current.parentElement.parentElement.querySelectorAll("li").forEach(el =>
				(current.parentElement !== el) ? el.classList.remove('open') : ''
			);
			setTimeout(() => {
				current.parentElement.classList.toggle('open');
			}, 100);
		}
	}
	render() {
		return (
			<>
				<header className="site-header mo-left header fullwidth">
					<div className="sticky-header main-bar-wraper navbar-expand-lg">
						<div className="main-bar clearfix">
							<div className="container clearfix">
								<div className="logo-header mostion">
									<Link to={"/"}> <h1>LOGO</h1> </Link>
								</div>

								<button className="navbar-toggler collapsed navicon  justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								<div className="header-nav navbar-collapse collapse myNavbar justify-content-end" id="navbarNavDropdown">
									<div className="logo-header mostion d-md-block d-lg-none">
										<Link to={'/'} className="dez-page"><h1>LOGO</h1></Link>
									</div>
									<div className='navbar-nav-con'>
										<ul className="nav navbar-nav">
											<li className='header-list'>
												<Link to={'/home'}  >Home </Link>
											</li>
											<li>
												<Link to={'/dealer-builder'} >Buyers</Link>
											</li>
											<li>
												<Link to={'/dealer-builder'} >Builders</Link>
											</li>
											{/* <li>
												<Link to={'/dealer-builder'} ></Link>
											</li> */}
											{/* <li >
												<Link to={'/UsersMaincontent'}>Users</Link>
											</li>
											<li  >
												<Link to={'/LandOwnerMaincontent'}>Land Owners</Link>
											</li>
											<li className='header-list'>
												<Link to={'/StaffMaincontent'}>Staff</Link>
											</li>
											<li className='header-list'>
												<Link to={'/AdminMaincontent'}>Admin </Link>
											</li> */}
											<li >
												<Link to={'/contact'}>Contact Us</Link>
											</li>
											<li >
												<Link to={'/about-us'}>About Us</Link>
											</li>
											<li >
												<Link to={'/layouts'}>Layouts</Link>
											</li>
										</ul>
									</div>
									<div><Link to={"/about-us"}><img src={Usericon} alt="user" className='usericon' /></Link></div>
									{/* <div className='btn-post-property'> <Link to={'/post-property'}><button className='btn btn-primary'>Post Property</button> </Link> </div> */}
									<div className='btn-post-property'> <Link to={'/Login-page'}><button className='btn btn-primary px-4'>Login</button> </Link> </div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</>
		)
	}
}
export default Header;
