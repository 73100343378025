import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, withRouter ,HashRouter as Router } from 'react-router-dom';
import './css/plugins.css';
import './css/skin/skin-1.css';
import './css/style.css';
import './css/templete.css';
import './plugins/slick/slick-theme.min.css';
import './plugins/slick/slick.min.css';
import AdminMaincontent from './markup/Element/Admin/MainContent';
import LandOwnerMaincontent from './markup/Element/LandOwners/MainContent';
import StaffMaincontent from './markup/Element/Staff/MainContent';
import UsersMaincontent from './markup/Element/Users/MainContent';
import Aboutus from './markup/Pages/Aboutus';
import Layouts from './markup/Pages/Layouts';
import Contact from './markup/Pages/Contact';
import DealerBuilder from './markup/Pages/DealerBuilder';
import Homepage from './markup/Pages/Homepage1';
import PostProperty from './markup/Pages/PostProperty';
import Loginpage2 from './markup/Pages/Loginpage';
import SignUp from "./markup/Pages/SignUp"
import store from './store/store';

import Design1 from './markup/Layout/Design1';
import Design2 from './markup/Layout/Design2';
import Design3 from './markup/Layout/Design3';
import Design4 from './markup/Layout/Design4';
import Design5 from './markup/Layout/Design5';
import Design6 from './markup/Layout/Design6';
import Design7 from './markup/Layout/Design7';
import Design8 from './markup/Layout/Design8';

const Main = withRouter(({ location }) => {
    return (<Provider store={store}>
    <div className="page-wraper">
        <Switch>
            <Route exact path='/'  component={Homepage} />
            <Route exact path='/home'  component={Homepage} />
            <Route exact path='/post-property'  component={PostProperty} />
            <Route exact path='/about-us'  component={Aboutus} />
            <Route exact path='/layouts'  component={Layouts} />
            <Route exact path='/contact'  component={Contact} />
            <Route exact path='/AdminMaincontent'  component={AdminMaincontent} />
            <Route exact path='/UsersMaincontent'  component={UsersMaincontent} />
            <Route exact path='/LandOwnerMaincontent'  component={LandOwnerMaincontent} />
            <Route exact path='/StaffMaincontent'  component={StaffMaincontent} />
            <Route exact path='/dealer-builder'  component={DealerBuilder} />
            <Route exact path='/Login-page'  component={Loginpage2} />
            <Route exact path='/SignUp'  component={SignUp} />
            <Route exact path='/Design1'  component={Design1} />
            <Route exact path='/Design2'  component={Design2} />
            <Route exact path='/Design3'  component={Design3} />   
            <Route exact path='/Design4'  component={Design4} />                  
            <Route exact path='/Design5'  component={Design5} />
            <Route exact path='/Design6'  component={Design6} />
            <Route exact path='/Design7'  component={Design7} />
            <Route exact path='/Design8'  component={Design8} />
        </Switch>
    </div>
</Provider>)
})


function App() {
    return (       
          <Router>
            <Main></Main>
          </Router>    
    )
}
export default App;



