import React, { useState,useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { getlayoutRegistrationData } from '../../store/action/LayoutAction';
import '../../css/design2.css';
import { commonFilter } from '../../utils/Common';

export default function Design2( { data } ) {
    const dispatch = useDispatch();
    const selector = useSelector(state =>state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modellayoutData) => {
        setModelData(modellayoutData)
        setShow(true);
    }
    useEffect(()=>{
            dispatch(getlayoutRegistrationData("SRCLAND00002"));
    },[]);
    useEffect(()=>{
        if(selector?.payload?.data?.message === "Success"){         
           setLayoutData(selector?.payload?.data?.data)
        }  
   },[selector?.payload?.data])      

    const design2 = {
        layout1: [
            {
                itemno: 'item1',
                box_number: '1',
                boxsize_r: "31'",
                boxsize_b: "36'-6''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 1) : 0 ,
            },
            {
                itemno: 'item2',
                box_number: '2',
                boxsize_r: "30'-3''",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 2) : 0 ,

            },
            {
                itemno: 'item3',
                box_number: '3',
                boxsize_t: "29'",
                boxsize_r: "29'-3''",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 3) : 0 ,

            },

        ],
        layout2: [
            {
                itemno: 'item6',
                box_number: '6',
                boxsize_r: "41'-9''",
                boxsize_b: "43'-9''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 4) : 0 ,
            },
            {
                itemno: 'item5',
                box_number: '5',
                boxsize_r: "30'",
                boxsize_b: "42'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 5) : 0 ,

            },
            {
                itemno: 'item4',
                box_number: '4',
                boxsize_r: "40'",
                boxsize_b: "25'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 6) : 0 ,

            },

        ],
        layout3: [
            {
                itemno: 'item7',
                box_number: '7',
                boxsize_l: "23'",
                boxsize_r: "25'-6''",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 7) : 0 ,
            },
            {
                itemno: 'item8',
                box_number: '8',
                boxsize_l: "30'",
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 8) : 0 ,
            },
            {
                itemno: 'item9',
                box_number: '9',
                boxsize_l: "30'",
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 9) : 0 ,
            },
            {
                itemno: 'item10',
                box_number: '10',
                boxsize_l: "30'",
                boxsize_r: "35'",
                boxsize_b: "35'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 10) : 0 ,
            },
        ],
        layout3a: [
            {
                itemno: 'item14',
                box_number: '14',
                boxsize_r: "28'",
                boxsize_b: "45'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 14) : 0 ,
            },
            {
                itemno: 'item13',
                box_number: '13',
                boxsize_r: "30'",
                boxsize_b: "45'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 13) : 0 ,
            },
            {
                itemno: 'item12',
                box_number: '12',
                boxsize_r: "30'",
                boxsize_b: "45'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 12) : 0 ,
            },
            {
                itemno: 'item11',
                box_number: '11',
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 11) : 0 ,
            },

        ],
        layout4: [
            {
                itemno: 'item15',
                box_number: '15',
                boxsize_l: "30'",
                boxsize_r: "32'-3''",
                boxsize_b: "36'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 15) : 0 ,
            },
            {
                itemno: 'item16',
                box_number: '16',
                boxsize_l: "30'",
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 16) : 0 ,
            },
            {
                itemno: 'item17',
                box_number: '17',
                boxsize_l: "30'",
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 17) : 0 ,
            },
            {
                itemno: 'item18',
                box_number: '18',
                boxsize_l: "30'",
                boxsize_r: "35'",
                boxsize_b: "35'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 18) : 0 ,
            },
        ],
        layout4A: [
            {
                itemno: 'item22',
                box_number: '22',
                boxsize_r: "35'",
                boxsize_b: "42'-6''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 22) : 0 ,
            },
            {
                itemno: 'item21',
                box_number: '21',
                boxsize_r: "30'",
                boxsize_b: "45'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 21) : 0 ,
            },
            {
                itemno: 'item20',
                box_number: '20',
                boxsize_r: "30'",
                boxsize_b: "45'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 20) : 0 ,
            },
            {
                itemno: 'item19',
                box_number: '19',
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 19) : 0 ,
            },

        ],
        layout5: [
            {
                itemno: 'item23',
                box_number: '23',
                boxsize_l: "36'-6''",
                boxsize_r: "38'-9''",
                boxsize_b: "42'-3''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 23) : 0 ,
            },
            {
                itemno: 'item24',
                box_number: '24',
                boxsize_l: "30'",
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 24) : 0 ,
            },
            {
                itemno: 'item25',
                box_number: '25',
                boxsize_l: "30'",
                boxsize_r: "30'",
                boxsize_b: "40'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 25) : 0 ,
            },
            {
                itemno: 'item26',
                box_number: '26',
                boxsize_l: "30'",
                boxsize_r: "35'",
                boxsize_b: "35'",
                status : layoutData.length > 0 ? commonFilter(layoutData, 26) : 0 ,
            },
        ],
        layout5A: [
            {
                itemno: 'item30',
                box_number: '30',
                boxsize_r: "41'-6''",
                boxsize_b: "42'-3''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 30) : 0 ,
            },
            {
                itemno: 'item29',
                box_number: '29',
                boxsize_r: '30',
                boxsize_b: "36'-6''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 29) : 0 ,
            },
            {
                itemno: 'item28',
                box_number: '28',
                boxsize_r: "30'",
                boxsize_b: "44'-6''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 28) : 0 ,
            },
            {
                itemno: 'item27',
                box_number: '27',
                boxsize_r: "30",
                boxsize_b: "39'-6''",
                status : layoutData.length > 0 ? commonFilter(layoutData, 27) : 0 ,
            },

        ],

    }

    return (
        <>
            {
                data === "true" && <Modal
                show={show}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
        >   
            <Modal.Header closeButton>
                <Modal.Title>Land Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Site Identification No: {modelData?.box_number} <br />
                Site Size Details:{modelData?.boxsize_t} {modelData?.boxsize_r} {modelData?.boxsize_b} {modelData?.boxsize_l}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </Modal>
            }
            <div className="container">
                <div className="">
                    <div className='design-2'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design2.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-2'>
                                {
                                    design2.layout2.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div class="roadcross1"></div>
                            </div>
                            <div className='layout-3'>
                                {
                                    design2.layout3.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div class="roadcross5"></div>
                            </div>
                            <div className='layout-3a'>
                                {
                                    design2.layout3a.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div class="roadcross2"></div>
                            </div>
                            <div className='layout-4'>
                                {
                                    design2.layout4.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div class="roadcross6"></div>
                            </div>
                            <div className='layout-4A'>
                                {
                                    design2.layout4A.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div class="roadcross3"></div>
                            </div>
                            <div className='layout-5'>
                                {
                                    design2.layout5.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div class="roadcross7"></div>
                            </div>
                            <div className='layout-5A'>
                                {
                                    design2.layout5A.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div class="roadcross4"></div>
                            </div>
                            <div class="layout-6"> 538 / 2A3 PART</div>
                            <div class="layout-7"> 539 / 6A </div>

                            <div class="layout-9"> 546 </div>
                            <div class="road-1">
                                <div class="left">EXISTING ROAD </div>
                                <div class="right">EXISTING ROAD </div>
                            </div>
                            <div class="road-2">
                                <div class="roadtext5">23 FEET LAYOUT ROAD</div>
                            </div>
                            <div class="road-3">
                                <div class="roadtext5">23 FEET LAYOUT ROAD</div>
                            </div>
                            <div class="road-4">
                                <div class="roadtext5">33 FEET LAYOUT ROAD</div>
                                <div class="left">539 / 1A PART</div>
                            </div>
                            <div class="road-5">
                                <div class="roadtext5">23 FEET LAYOUT ROAD</div>
                            </div>
                            <div class="road-6">
                                <div class="roadtext6">532 / 2C1</div>
                                <div class="left">ROAD</div>
                                <div class="right">ROAD</div>
                            </div>
                            <div class="road-7">
                                <div class="roadtext7"> ROAD</div>
                            </div>
                            <div class="whitebox1">
                                <div class="layout-8"> 545 </div>
                            </div>
                            <div class="whitebox2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
