import React from 'react';
import arrowright from '../../../images/Dashboard/arrowright.png';

export default function Payments({ paymentdetails }) {

    const rows = [
        {
            sitedetails: 'Plot 23',
            invoice: 'Inv: MGL524874',
            date: '14 Apr 2022',
            time: 'at 8:00 PM',
            order: '20',
            amount: '₹420.84',
            status: 'Pending'
        },
        {
            sitedetails: 'Plot 203',
            invoice: 'Inv: MGL524250',
            date: '12 Apr 2022',
            time: 'at 8:00 PM',
            order: '01',
            amount: '₹244.80',
            status: 'Paid'
        },
        {
            sitedetails: 'Plot 423',
            invoice: 'Inv: MGL524874',
            date: '12 Apr 2022',
            time: 'at 9:00 AM',
            order: '02',
            amount: '₹200.00',
            status: 'Unpaid'
        },
        {
            sitedetails: 'Plot 10',
            invoice: 'Inv: MGL524140',
            date: '24 Mar 2022',
            time: 'at 8:00 PM',
            order: '01',
            amount: '₹84.00',
            status: 'Paid'
        },
        {
            sitedetails: 'Plot 6',
            invoice: 'Inv: MGL524245',
            date: '10 Mar 2022',
            time: 'at 8:00 PM',
            order: 'Withdraw',
            amount: '₹420.84',
            status: 'Pending'
        },
        {
            sitedetails: 'Plot 50',
            invoice: 'Inv: MGL524254',
            date: '08 Mar 2022',
            time: 'at 8:00 PM',
            order: 'Technology',
            amount: '₹400.11',
            status: 'Pending'
        },
        {
            sitedetails: 'Plot 5',
            invoice: 'Inv: MGL524487',
            date: '02 Mar 2022',
            time: 'at 7:00 PM',
            order: '01',
            amount: '₹420.84',
            status: 'Paid'
        },
        {
            sitedetails: 'Plot 236',
            invoice: 'Inv: MGL524598',
            date: '01 Mar 2022',
            time: 'at 8:00 PM',
            order: 'Withdraw',
            amount: '₹510.10',
            status: 'Paid'
        },
    ];

    return (
        <>
            <span className='listings-heading'>Payments</span>
            <div className='mt-4 ml-lg-5 mr-lg-5 ml-md-2'>
                <div class="form-group has-search-payments">
                    <span class="fa fa-search form-control-payments"></span>
                    <input type="text" class="form-control" placeholder="Search Invoices" />
                </div>
            </div>

            <div className='table-listings payments-listing'>
                <table class="table-responsive">
                    <thead>
                        <tr className='table-listings-heading'>
                            <th scope="col">Place</th>
                            <th scope="col">Date</th>
                            <th scope="col">Order/Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (<tr key={index} >
                            <td>
                                <span className='sitestreet-listings'>{row.sitedetails}</span><br />
                                <span className='district-listings'>{row.invoice} </span>
                            </td>
                            <td>
                                <span className='date-listings' > {row.date} </span><br />
                                <span className='time-listings'> {row.time} </span>
                            </td>
                            <td className="sqft-listings">{row.order}</td>
                            <td className="amount-listings">{row.amount}</td>
                            <td>
                                <span className={`land-paymentstatus ${row.status}`}>{row.status}</span>
                            </td>
                            <td>
                                <button className='btn custombtn-view-listings'>View</button>
                            </td>
                        </tr>))}
                    </tbody>
                </table>
                <div className='d-flex mt-lg-2 flex-row-reverse next-btn-container'>
                    <button className='btn next-cus-btn' onClick={() => paymentdetails(true, "Add List")}> Next <img src={arrowright} alt="" /></button>
                </div>
            </div>

        </>

    )
}
