import React, { useState } from "react";

// Icons

import arrowicon from '../../../images/Dashboard/arrowicon.png';
import Dashboardicon from "../../../images/Dashboard/dashboardicon.png";
import Dashboardwhite from '../../../images/Dashboard/dashboardwhite.png';
import Listingicon from '../../../images/Dashboard/listingicon.png';
import Listingiconwhite from '../../../images/Dashboard/listingicon3.png';
import Logouticon2 from '../../../images/Dashboard/logouticon2.png';
import Profileicon from '../../../images/Dashboard/profileicon.png';
import Profileiconwhite from '../../../images/Dashboard/profileicon2.png';
import Rupeeicon from '../../../images/Dashboard/rupeeicon.png';
import Rupeeiconwhite from '../../../images/Dashboard/rupeeicon2.png';
import Supporticon from '../../../images/Dashboard/supporticon.png';
import usericon from '../../../images/Dashboard/usericon.png';


// Pages

import Addlistings from "./Addlistings";
import Addlistings2 from "./Addlistings2";
import Dashboard from "./Dashboard";
import Listings from "./Listings";
import PaymentDetails from "./PaymentDetails";
import Payments from "./Payments";
import Profile from "./Profile";


import UploadProof from "./UploadProof";


export default function NavPage() {

    const [dashboard, setDashboard] = useState(true);
    const [listings, setListings] = useState(false);
    const [profile, setProfile] = useState(false);
    const [payments, setPayments] = useState(false);
    const [addListing, setAddListing] = useState(false);
    const [payemntdetails, setPayemntdetails] = useState(false);
    const [uploadFiles, setUploadFiles] = useState(false);
    const [pageListData, setPageListData] = useState([]);
    const [listingPage, setListingPage] = useState(true);



    const dashboardHandler = () => {
        setDashboard(true);
        setListings(false);
        setProfile(false);
        setPayments(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const listingsHandler = () => {
        setDashboard(false);
        setListings(true);
        setProfile(false);
        setPayments(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const profileHandler = () => {
        setDashboard(false);
        setListings(false);
        setProfile(true);
        setPayments(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const paymentsHandler = () => {
        setDashboard(false);
        setListings(false);
        setProfile(false);
        setPayments(true);
        setAddListing(false);
        setPayemntdetails(false)
        setUploadFiles(false);
    }

    const paymentdetailsData = (page, data) => {
        setDashboard(false);
        setListings(false);
        setProfile(false);
        setPayments(false);
        setAddListing(false);
        setPayemntdetails(true)
        setUploadFiles(false);
    }

    const addListingData = (page, data) => {
        setDashboard(false);
        setListings(false);
        setProfile(false);
        setPayments(false);
        setAddListing(true);
        setPayemntdetails(false)
        setPageListData(page);
        setUploadFiles(false);
        setListingPage(true)
    }

    const moveListingData = (data) => {
        setDashboard(false);
        setListings(false);
        setProfile(false);
        setPayments(false);
        setAddListing(true);
        setPayemntdetails(false)
        setPageListData(data);
        setUploadFiles(false);
        setListingPage(false)

    }

    const uploadImagePageMove = (page, data) => {
        setDashboard(false);
        setListings(false);
        setProfile(false);
        setPayments(false);
        setAddListing(false);
        setPayemntdetails(false)
        setUploadFiles(true);

    }

    return (
        <>
            <div className="row landowner">
                <div className="col-lg-2 col-md-2 col-sm-1 navigation-row">
                    <div class="useruploadicon-wrapper">
                        <img src={usericon} className='useruploadicon' alt='usericon' />
                        <input type="file" />
                    </div>
                    <div className="mb-lg-3">
                        <p className="username-dashboard">VASANTHSENAN M</p>
                        <p className="usermailid-dashboard">vasanthsasi@gmail.com</p>
                    </div>

                    <div>
                        <div className="row navpage-icon-container">
                            <div className={dashboard === true ? "col-lg-6 col-md-12 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 text-center landowner-menu-col1"} onClick={dashboardHandler}>
                                <div>
                                    {dashboard === true ? <img src={Dashboardwhite} className='Landowners-icon' alt='dashboard' /> : <img src={Dashboardicon} alt='dashboard' />}</div> <div className="icondashboard-word">Dashboard</div>
                            </div>
                            <div className={listings === true ? "col-lg-6 col-md-12 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 text-center  landowner-menu-col2"} onClick={listingsHandler} >
                                <div>
                                    {listings === true ? <img src={Listingiconwhite} className='Landowners-icon' alt='listings' /> : <img src={Listingicon} alt='dashboard' />}</div>
                                <div className="">Listings</div>
                            </div>

                        </div>
                        <div className="row navpage-icon-container2">
                            <div className={payments === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col3"} onClick={paymentsHandler}>
                                <div>
                                    {payments === true ? <img src={Rupeeiconwhite} className='Landowners-icon' alt='profile' /> : <img src={Rupeeicon} alt='profile' />} </div> <div className="icondashboard-word">Payments</div>
                            </div>
                            <div className={profile === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12  col-sm-3 text-center landowner-menu-col4"} onClick={profileHandler}>
                                <div>
                                    {profile === true ? <img src={Profileiconwhite} className='Landowners-icon' alt='profile' /> : <img src={Profileicon} alt='profile' />} </div> <div className="icondashboard-word">Profile</div>
                            </div>
                        </div>
                    </div>

                    <div className="row logout-container">
                        <div className="col-lg-3 col-md-3"> <img src={Logouticon2} className='logout-icon2' alt='logout' /></div>
                        <div className="col-lg-5 col-md-3"><p className="logout-word">Logout</p></div>
                        <div className="col-lg-3 col-md-3"><img src={arrowicon} alt='arow' /></div>
                    </div>
                    <div className="row help-container">
                        <div className="col-lg-3 col-md-3"><img src={Supporticon} className='logout-icon2' alt='support' /></div>
                        <div className="col-lg-5 col-md-3"><p className="logout-word">Help</p></div>
                        <div className="col-lg-3 col-md-3"><img src={arrowicon} alt='arow' /></div>
                    </div>
                </div>
                <div className="col-lg-10 col-md-10 navigation-container" style={{ flex: 1, backgroundColor: "white", borderRadius: '20px' }}>
                    {dashboard && (
                        <Dashboard />
                        // <UploadProof />
                        // <Addlistings2 />
                        // <Listings addListing={addListingData} viewListingData={moveListingData} />
                    )}
                    {listings && (
                        <Listings addListing={addListingData} viewListingData={moveListingData} />
                    )}
                    {addListing && (
                        <Addlistings pageData={pageListData} uploadPage={uploadImagePageMove} pageMove={listingPage} />
                    )}
                    {profile && (
                        <Profile />
                    )}
                    {payments && (
                        <Payments paymentdetails={paymentdetailsData} />
                    )}

                    {payemntdetails && (
                        <PaymentDetails />
                    )}
                    {uploadFiles && (
                        <Addlistings2 />
                    )}
                </div>
            </div>
        </>
    )
}

