import React from 'react';
import CarouselDealer from '../Element/CarouselDealer';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';

//Images
import bnr6 from '../../images/dealer-builder/bnr6.png';
import profilepic from '../../images/dealer-builder/profile.png';


function DealerBuilder() {
	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full p-b50">
						<div className="container">
							<p className='heading-dealer-builder'>Premium penthouse in central Barcelona <br /> with panoramic views</p>
							<div className="row">
								<div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
									<div className="container">
										<img src={bnr6} alt="Site" className='img-fluid' />
									</div>

									<CarouselDealer />
									<p className='center-content-text'>
										FEDORS GROUP offers an exclusive FOR SALE elegant large 5-room apartment on Vincent Hložník Street in the Condominium Renaissance residential complex.
										<br /><br />
										Thanks to its unique location, the property has access to a large Japanese garden with an area of 35 m2, which can be accessed directly from the bedroom. The front of the apartment is at the height of the third floor, so the terrace is located just above the treetops, which gives the apartment a unique atmosphere. Overall, the apartment has a direct view of the Danube River and the surrounding forests.
										<br /><br />
										The apartment offers extraordinary comfort, has a first-class interior from the leading architectural office Cakov Makara and equipment from renowned world furniture manufacturers. The overall atmosphere of the apartment is completed
										<br /><br />
										<h4>Basic characteristics:</h4>
										<ul className='Basic-char-ul'>
											<li>=&gt; number of rooms: 5</li>
											<li>=&gt; 2nd floor of 5</li>
											<li>=&gt; apartment area: 223.92 <span> &#13217; </span></li>
											<li>=&gt; terrace area: 27.09 <span> &#13217; </span></li>
											<li>=&gt; balcony area: 6.63 <span> &#13217; </span></li>
											<li> =&gt; area of ​​the Japanese garden: 35 <span> &#13217; </span> </li>
										</ul> <br />

										<h4>Layout solution:</h4>
										Kitchen, living room, study, 4 bedrooms, 2 bathrooms, wardrobe, fireplace. Two garage parking spaces in the underground garage.<br /><br />
										The apartment is divided into day and night zone. The living area consists of a large living room, which is connected to the kitchen and dining room. In this part of the apartment there is also a study, which is very subtly separated from the living area by an elegant glass wall and wooden beams. From the living area there is a smooth transition to the night wing, where there are two rooms, a wardrobe, a shared bathroom and a master bedroom with a separate bathroom.<br /><br />
										<h4>Execution and furnishing of the apartment:</h4>
										The apartment has intelligent control via a mobile application. Premium natural materials - wood, stone tiles, cast concrete - are found in many places in the living space.<br /><br />
										The kitchen of the LEICHT brand with SIEMENS appliances has been made to measure, bathrooms and toilets are equipped with sanitary ware from the manufacturers VILLEROY BOCH and HANSGROHE. In the master bathroom you will find the design edition of the AXOR MASSAUD brand, the master bedroom is dominated by the RUF BETTEN bed. The living room is equipped with ROLF BENZ brand products.<br /><br />

										<h4>Location:</h4>
										The property is located above Devínská cesta, there is an excellent transport connection. The nearby housing estate provides complete civic amenities, including shops, cafes, restaurants, schools, kindergartens and many other benefits.<br /><br />

										<h4>Fedors group opinion:</h4>
										This beautiful apartment offers an incredible atmosphere. The above-standard area creates the impression of a family house, which is further enhanced by the view of the Danube River and the forests in its basin. The apartment is furnished extremely tastefully and really timelessly. From our point of view, this is one of the most beautiful apartments in the current offer - it is guaranteed to provide its owners with a new and luxurious standard of living.
									</p>
									<div>
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" title='googlemaps' tabindex="0"></iframe>
									</div>
								</div>

								<div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
									<div className="sticky-top">
										<div className="custom-form-container mb-4">
											<h4 className='mb-4'>Contact Us</h4>
											<form >
												<div className="form-group">
													<label for="name">Full Name</label>
													<input type="text" className="form-control" id="" placeholder="Your full name" />
												</div>
												<div className="form-group">
													<label for="number">Phone Number</label>
													<input type="number" className="form-control" id="number" placeholder="Phone Number" />
												</div>
												<div class="form-group">
													<label for="email">Email</label>
													<input class="form-control" id="email" placeholder='Your E-mail' />
												</div>
												<div class="form-group">
													<label for="email">Your Message</label>
													<textarea class="form-control" id="textarea" placeholder='Your message' rows="7"></textarea>
												</div>
												<div className="form-group">
													<div className="form-check">
														<input className="form-check-input" type="checkbox" id="gridCheck" />
														<label className="form-check-label" for="gridCheck">
															I agree to the processing of <span style={{ textDecoration: 'underline' }} >personal data.</span>
														</label>
													</div>
												</div>
												<div className=''>
													<button type="submit" className="btn btn-primary btn-Dealer-send-message">Send Message</button>
												</div>
											</form>
										</div>


										<div className="candidates-are-sys">
											<div className="candidates-bx">
												<div className="testimonial-pic radius"><img src={profilepic} alt="" width="100" height="100" /></div>
												<div className='testimonial-head'>Haylie Donin</div>
												<div className="testimonial-text">
													<p>+34 555 781 731 <br />
														haylie.donin@realestate.es</p>
													<p className='view-broker-profile'>View broker profile</p>
												</div>

											</div>
										</div>

										<div className="quote-bx">
											<div className="quote-info">
												<p className='char-heading'>Brief characteristics</p>
												<p className='char-content'> <b>City: </b>Barcelona I. </p>
												<p className='char-content'> <b>Street: </b>Vincent ala Carne </p>
												<p className='char-content'> <b>Garages: </b> 2 cars </p>
												<p className='char-content'> <b>Type: </b>5 rooms </p>
												<p className='char-content'> <b>Number of rooms: </b>5</p>
												<p className='char-content'> <b>Usable area: </b>224 <span> &#13217; </span></p>
												<p className='char-content'> <b>Total area: </b>307 <span> &#13217; </span></p>
												<p className='char-content'> <b>Insulated object: </b>Yes</p>
												<p className='char-content'> <b>Balcony: </b>Yes</p>
												<p className='char-content'> <b>Terrace: </b>Yes</p>
												<p className='char-content'> <b>Number of bathrooms: </b>1</p>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default DealerBuilder;