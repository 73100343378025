import React from 'react';


export default function Frontend() {


    return (
        <>
            <div className='frontendoption-con d-flex justify-content-between'>
                <div className='m-lg-4'>
                    <span style={{ fontSize: '20px', fontWeight: '800' }}>Front End Options</span> <br />
                    <span style={{ fontSize: '15px' }}>Update your site info</span> <br />
                    <span className='basicinfo-admin'>Basic Info</span>
                    <p className='basicinfo-border'
                        style={{
                            background: '#D9DD00',
                            height: '12px',
                            position: 'relative',
                            top: '30px',
                            left: '100px',
                            width: '100px',
                            borderRadius: '10px',
                        }}
                    />
                </div>
                <div className='m-lg-5'>
                    <button className='btn addlistings-cus-btn mx-lg-5'> <span style={{ fontWeight: 'bolder' }}>Save Changes</span> </button>
                </div>
            </div>
            <form className='profile-form-container'>
                <div class="form-row frontend-cus-form-row">
                    <div class="form-group form-group-frontend col-md-6 pr-lg-5">
                        <label for="profile1name">Site Name</label>
                        <input type="text" class="form-control from-control-cus-frontend" id="streetname" value="Karthik" />
                    </div>
                    <div class="form-group form-group-frontend col-md-6 pl-lg-5">
                        <label for="profile2name">Meta Keywords</label>
                        <input type="text" class="form-control from-control-cus-frontend" id="numberofplots" value="Land, Promoters, Src, Website" />
                    </div>
                </div>
                <div class="form-row frontend-cus-form-row">
                    <div class="form-group form-group-frontend col-md-6 pr-lg-5">
                        <label for="email">Email</label>
                        <input type="text" class="form-control from-control-cus-frontend" value="srcpromoters@gmail.com" />
                    </div>
                    <div class="form-group form-group-frontend col-md-6 pl-lg-5">
                        <label for="dob">Mobile Number</label>
                        <input type="text" class="form-control from-control-cus-frontend" id="mobile" value="+123 456 7890" />
                    </div>
                </div>
                <div class="form-group form-group-frontend">
                    <label for="email">Address</label>
                    <input type="text" class="form-control from-control-cus-frontend" value="Src land promoters, Erode - 638 001" />
                </div>
                <div class="form-group form-group-frontend">
                    <label for="email">Meta Description</label>
                    <input type="text" class="form-control from-control-cus-frontend" value="Loppersum is a village and former municipality in the province of Groningen in the northeast of the Netherlands." />
                </div>
                <div class="form-group form-group-frontend">
                    <label for="email">Copyrights</label>
                    <input type="text" class="form-control from-control-cus-frontend" value="@2022. All rights reserved by Src Land Promoters" />
                </div>
            </form>
        </>
    )
}
