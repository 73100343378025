import React, { useState } from "react";
import CloseButton from 'react-bootstrap/CloseButton';
import '../../../css/uploadimage.css';

import Uploadicon from '../../../images/Dashboard/uploadicon.png';


export default function UploadProof({ children }) {

    const [selectedImages, setSelectedImages] = useState([]);

    const onSelectFile = (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        });

        setSelectedImages((previousImages) => previousImages.concat(imagesArray));

        // FOR BUG IN CHROME
        event.target.value = "";
    };

    function deleteHandler(image) {
        setSelectedImages(selectedImages.filter((e) => e !== image));
        URL.revokeObjectURL(image);
    }

    return (

        <div className="uploadimage">
            <section>
                <label>
                    <img src={Uploadicon} alt="uploadicon" />
                    <br />
                    <span className="li-addlistings-details">{children}</span>
                    <span className="li-addlistings-notes">PNG, JPG and GIF files are allowed</span>
                    <input
                        type="file"
                        name="images"
                        onChange={onSelectFile}
                        multiple
                        accept="image/png , image/jpeg, image/webp"
                    />
                </label>
                <br />

                <input type="file" multiple />
                <div className="images">
                    {selectedImages &&
                        selectedImages.map((image) => {
                            return (
                                <div key={image} className="image">
                                    <CloseButton className="closebutton-uploadpage" onClick={() => deleteHandler(image)} />
                                    <img src={image} height="200" alt="upload" />
                                </div>
                            );
                        })}
                </div>
                {/* {selectedImages.length > 0 &&
                    (selectedImages.length > 10 ? (
                        <p className="error">
                            You can't upload more than 10 images! <br />
                            <span>
                                please delete <b> {selectedImages.length - 10} </b> of them{" "}
                            </span>
                        </p>
                    ) : (
                        <button
                            className="upload-btn"
                            onClick={() => {
                                console.log(selectedImages);
                            }}
                        >
                            UPLOAD {selectedImages.length} IMAGE
                            {selectedImages.length === 1 ? "" : "S"}
                        </button>
                    ))} */}
            </section>

        </div >
    );
};

