import React from 'react';


import FeatureCities from '../Element/FeatureCities';
import SiteDetails from '../Element/SiteDetails';
import GetConnected from './../Element/GetConnected';
import IndexBanner from './../Element/IndexBanner';
import Owltestimonial from './../Element/Owlblog1';
import RecentListing from './../Element/RecentListing';
import Footer from './../Layout/Footer';
import Header from './../Layout/Header';


var bnr5 = require('./../../images/banner/bnr5.png');
var arrow = require('./../../images/banner/arrow.png');

export default function HomePage3() {
	return (
		<>
			<Header />
			<IndexBanner />
			<FeatureCities />
			<RecentListing />

			<div style={{ backgroundImage: "url( " + bnr5 + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
				<div className="row mb-5 p-4" >
					<div className="col-lg-2"></div>
					<div className="col-lg-4">
						<p className='Subscribe-email'>Subscribe Our Email Address <br /> For Future Lettest News & Updates </p>
					</div>
					<div className="col-lg-3">
						<div className="form-group">
							<div className="input-group">
								<input type="text" className="form-control  subscribe-input" placeholder="Type your email address" />
								<div className="input-group-append append-arrow">
									<span className="input-group-text bg-white"> <img src={arrow} alt="" width={15} height={15} className='img-fluid' /> </span>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<SiteDetails />
			<Owltestimonial />
			<GetConnected />
			<Footer />

		</>
	)
}



