import React, { Component } from 'react';

var landownericon = require('../../../images/Dashboard/landownericon.png');
var searchicon = require('../../../images/Dashboard/searchicon.png');
var locationicon = require('../../../images/Dashboard/locationicon.png');
var logouticon = require('../../../images/Dashboard/logouticon3.png');

class Header extends Component {
    state = {
        // initial state
        show: false,
    }

    handleClose = () => {
        this.setState({ show: false });
    };
    handleShow = () => {
        this.setState({ show: true });
    };
    componentDidMount() {
        // sidebar open/close

        var Navicon = document.querySelector('.navicon');
        var sidebarmenu = document.querySelector('.myNavbar ');

        function toggleFunc() {
            sidebarmenu.classList.toggle('show');
            //   Navicon.classList.toggle('open');
        }
        Navicon.addEventListener('click', toggleFunc);

        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            current.parentElement.parentElement.querySelectorAll("li").forEach(el =>
                (current.parentElement !== el) ? el.classList.remove('open') : ''
            );
            setTimeout(() => {
                current.parentElement.classList.toggle('open');
            }, 100);
        }
    }
    render() {
        return (
            <>
                <header className="site-header mo-left header fullwidth">
                    <div className="sticky-header main-bar-wraper navbar-expand-lg" >
                        <div className="main-bar clearfix" style={{ backgroundColor: '#F4F7FE' }}>
                            <div className="container-fluid clearfix">
                                <div className="logo-header mostion d-flex logo-topnavbar ">
                                    <img src={landownericon} alt="searchicon" className='landownericon' />
                                    <p className='m-3'>Users</p>
                                </div>

                                <button className="navbar-toggler collapsed navicon  justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <div className="header-nav navbar-collapse collapse myNavbar justify-content-end mr-lg-5" id="navbarNavDropdown">
                                    <div className="logo-header mostion d-flex d-lg-none logo-topnavbar m-3">
                                        <img src={landownericon} alt="searchicon" className='landownericon' />
                                        <p className='m-3'>Users</p>
                                    </div>
                                    <div className='navbar-nav-con'>
                                        <ul className="nav navbar-nav">
                                            <li>
                                                <div className="topsearchbox-container m-md-2 d-flex">
                                                    <img src={searchicon} alt="searchicon" className='' />
                                                    <input class="form-control form-control-sm" type="text" placeholder="Search.." style={{ height: 'auto', backgroundColor: '#F4F7FE', border: 'none' }}></input>
                                                </div>
                                            </li>
                                            <li >
                                                <div className="toplocationbox-container d-flex m-md-2">
                                                    <img src={locationicon} alt="locationicon" className='' />
                                                    <input class="form-control form-control-sm" type="text" placeholder="Erode" style={{ height: 'auto', backgroundColor: '#F4F7FE', border: 'none' }}></input>
                                                </div>
                                            </li>
                                            <li >
                                                <button className="btn btn-primary mt-1 ml-5">
                                                    <img src={logouticon} alt="logouticon" className='mr-2' />Logout</button>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </header >



            </>
        )
    }
}
export default Header;


