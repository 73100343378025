import React from 'react'
import UploadProof from './UploadProof'

export default function Addlistings2() {

    const upload = [
        {
            uploadProof: <UploadProof> Upload Plot Map </UploadProof>,
        },
        {
            uploadProof: <UploadProof>Upload Approved Document</UploadProof>,
        },
        {
            uploadProof: <UploadProof>Upload Address Proof</UploadProof>,
        },

    ]
    return (
        <>
            <div className='uploadimage'>
                <p className='addlistings1-heading'>Add Listings</p>
                <div className='d-flex justify-content-center process-container'>
                    <div className='text-center processdot1-con'>
                        <span class="processdot2"></span><br />
                        <span>Basic Details</span>
                    </div>
                    <span className='processline'></span>
                    <div className='text-center processdot2-con'>
                        <span class="processdot1"></span><br />
                        <span>Proof Upload</span>
                    </div>
                </div>

                <ul className='d-flex justify-content-center my-5'>
                    {upload.map((item, index) => (
                        <li key={index} >
                            <div>{item.uploadProof}</div>
                        </li>
                    ))}
                </ul>

                <div className='custombtn-addlistings-div '>
                    <button type="submit" class="btn custombtn-addlistings">Add</button>
                </div>
            </div>
        </>
    )
}
