import React from 'react';
import Footer from './../Layout/Footer';
import Header from './../Layout/Header';

var phone = require('./../../images/contacts/phone.png');
var email = require('./../../images/contacts/email.png');

function Contact() {
	return (
		<>
			<Header />
			<div className="pageContent bg-white">
				<div className="container">
					<div className='text-contact-con'>
						<h2>Get In Touch</h2>
						<hr style={{
							color: 'blue',
							backgroundColor: 'blue',
							height: 5, width: 120,
						}} />

						<p>There are many variations of passages of lorem Ipsum available but the majority have suffered alteration in some form injected is a humour randomised words which look slightly believable.</p>
					</div>

					<div className="row">

						<div className="col-lg-8">
							<div className="form-input-con">
								<form >
									<div className="form-row">
										<div className="form-group col-md-6">
											<label for="name">Full Name</label>
											<input type="text" className="form-control" id="" placeholder="Full Name" />
										</div>
										<div className="form-group col-md-6">
											<label for="email">Email</label>
											<input type="password" className="form-control" id="inputPassword4" placeholder="Password" />
										</div>
									</div>
									<div className="form-group">
										<label for="text">Subject</label>
										<input type="text" className="form-control" id="inputAddress" placeholder="Subject" />
									</div>
									<div class="form-group">
										<label for="comments">Comments</label>
										<textarea class="form-control" id="textarea" placeholder='Type your comments' rows="5"></textarea>
									</div>
									<div className="form-group">
										<div className="form-check">
											<input className="form-check-input" type="checkbox" id="gridCheck" />
											<label className="form-check-label" for="gridCheck">
												I read terms and condition
											</label>
										</div>
									</div>
									<button type="submit" className="btn btn-primary w-5">Submit Request</button>
								</form>
							</div>
						</div>
						<div className="col-lg-4 text-center m-t60 ">
							<div className='connect-over-con'>
								<div className="row connect-row-details">
									<div className="col">
										<img src={phone} alt="" width={35} className='mb-4' />
										<h5>Call or Phone</h5>
										<p>+00 123 456 789 <br />
											+00 987 654 321</p>
									</div>
								</div>
								<div className="row connect-row-details">
									<div className="col">
										<img src={email} alt="" width={35} className='mb-4' />
										<h5>Email Address</h5>
										<p>mail.youremail.com <br />
											youremail.mail.com</p>

									</div>
								</div>
								<div className="row connect-row-detail1">
									<div className="col">
										<img src={phone} alt="" width={35} className='mb-3' />
										<h5>Call or Phone</h5>
										<p>4517 Washington Ave. Manchester, <br />
											Kentucky 39495</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div >

			<Footer />
		</>
	)
}

export default Contact;



