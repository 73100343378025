import React from 'react';


import foto1 from '../../images/dealer-builder/foto1.png';
import foto2 from '../../images/dealer-builder/foto2.png';
import foto3 from '../../images/dealer-builder/foto3.png';

import flat from '../../images/dealer-builder/flat.png';
import meter from '../../images/dealer-builder/meter.png';
import pin from '../../images/dealer-builder/pin.png';

import left from '../../images/dealer-builder/left.png';
import right from '../../images/dealer-builder/right.png';



export default function CarouselDealer() {
	return (
		<>
			<div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
				<div className="row">
					<div className="col-md-1 col-sm-12">
						<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"> <span ><img className='carousel-control-prev-custom' src={left} alt="" /></span>
						</button>
					</div>
					<div className="col-md-10 col-sm-12">
						<div className="carousel-inner">
							<div className="carousel-item active">
								<div className="card-wrapper container-sm d-flex  justify-content-around">
									<img src={foto1} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto2} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto3} className="img-fluid custom-img-fluid" alt="..." />
								</div>
							</div>
							<div className="carousel-item">
								<div className="card-wrapper container-sm d-flex  justify-content-around">
									<img src={foto1} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto2} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto3} className="img-fluid custom-img-fluid" alt="..." />
								</div>
							</div>
							<div className="carousel-item">
								<div className="card-wrapper container-sm d-flex  justify-content-around">
									<img src={foto1} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto2} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto3} className="img-fluid custom-img-fluid" alt="..." />
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-1 col-sm-12">
						<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next"> <span ><img className='carousel-control-next-custom' src={right} alt="" /></span>
						</button>
					</div>
				</div>
			</div>

			<div className="row px-lg-5 py-5">
				<div className="col-lg-4 col-md-12 col-sm-12 d-flex">
					<img src={flat} alt="" className='img-fluid m-3' />
					<p className='carasoul-heading'>a Flat</p>
				</div>
				<div className="col-lg-4 col-md-12 col-sm-12 d-flex">
					<img src={meter} alt="" className='img-fluid m-3' />
					<p className='carasoul-heading'>224 <span> &#13217; </span></p>
				</div>
				<div className="col-lg-4 col-md-12 col-sm-12 d-flex">
					<img src={pin} alt="" className='img-fluid m-3' />
					<p className='carasoul-heading'>Barcelona I.</p>
				</div>
			</div>

			<div className="row card-mortgage mb-5">
				<div className="col-md-6 col-sm-12 pt-3">
					<h6>Mortgage since:</h6>
					<h4>807.57 €/ month</h4>
				</div>
				<div className="col-md-6 col-sm-6 text-center pt-4">
					<button className='btn btn-primary btn-mortgage'>Get a mortgage</button>
				</div>
			</div>
		</>
	)
}
