import React, { useState } from 'react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';

function Layouts() {
	const [show, setShow] = useState(false);
	return (
		
		<div className="page-wraper bg-white">
			<Header />

			
			<Footer />

			{/* //Edit Profile model start */}
			
		</div >
	)

}
export default Layouts;