import React, { useState,useEffect } from "react";
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import '../../css/design7.css'
import {getlayoutRegistrationData} from "../../store/action/LayoutAction";
import  { commonFilter } from "../../utils/Common";


export default function Design7( { data }) {
    const dispatch = useDispatch();
    const selector = useSelector(state =>state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modellayoutData) => {
        setModelData(modellayoutData)
        setShow(true);
    }
    useEffect(()=>{
            dispatch(getlayoutRegistrationData("SRCLAND000040"));
    },[]);
    useEffect(()=>{
        if(selector?.payload?.data?.message === "Success"){         
           setLayoutData(selector?.payload?.data?.data)
        }  
   },[selector?.payload?.data])

    const design7 = {
        layout1: [
            {
                itemno: 'item1',
                box_number: '1',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 1) : 0 ,
            },
            {
                itemno: 'item2',
                box_number: '2',
                boxsize_t: '',
                boxsize_r: '14.3',
                boxsize_b: '9.1',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 2) : 0 ,
            },
            {
                itemno: 'item3',
                box_number: '3',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 3) : 0 ,
            },
            {
                itemno: 'item4',
                box_number: '4',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '9.1',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 4) : 0 ,
            },
            {
                itemno: 'item5',
                box_number: '5',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 5) : 0 ,
            },
            {
                itemno: 'item6',
                box_number: '6',
                boxsize_t: '',
                boxsize_r: '12.5',
                boxsize_b: '1.5+6.6',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 6) : 0 ,
            },
           
        ],
        layout2: [
            {
                itemno: 'item7',
                box_number: '7',
                boxsize_t: '1.5+12.6',
                boxsize_r: '15.2',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 7) : 0 ,
            },
            {
                itemno: 'item8',
                box_number: '8',
                boxsize_t: '9.1',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 8) : 0 ,
            },
            {
                itemno: 'item9',
                box_number: '9',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 9) : 0 ,
            },
            {
                itemno: 'item10',
                box_number: '10',
                boxsize_t: '9.1',
                boxsize_r: '15.2',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 10) : 0 ,
            },
            {
                itemno: 'item11',
                box_number: '11',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 11) : 0 ,
            },
            {
                itemno: 'item12',
                box_number: '12',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 12) : 0 ,
            },
            {
                itemno: 'item13',
                box_number: '13',
                boxsize_t: '',
                boxsize_r: '12.2',
                boxsize_b: '9.1',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 13) : 0 ,
            },
            {
                itemno: 'item14',
                box_number: '14',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 14) : 0 ,
            },
            {
                itemno: 'item15',
                box_number: '15',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '9.1',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 15) : 0 ,
            },
            {
                itemno: 'item16',
                box_number: '16',
                boxsize_t: '',
                boxsize_r: '12.2',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 16) : 0 ,
            },
        ],
        layout3: [
            {
                itemno: 'item17',
                box_number: '17',
                boxsize_t: '',
                boxsize_r: '16.8',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 17) : 0 ,
            },
            {
                itemno: 'item18',
                box_number: '18',
                boxsize_t: '7.0',
                boxsize_r: '15.2',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 18) : 0 ,
            },
            {
                itemno: 'item19',
                box_number: '19',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 19) : 0 ,
            },
            {
                itemno: 'item20',
                box_number: '20',
                boxsize_t: '9.1',
                boxsize_r: '15.2',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 20) : 0 ,
            },
            {
                itemno: 'item21',
                box_number: '21',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 21) : 0 ,
            },
            {
                itemno: 'item22',
                box_number: '22',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 22) : 0 ,
            },
            {
                itemno: 'item23',
                box_number: '23',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '9.1',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 23) : 0 ,
            },
            {
                itemno: 'item24',
                box_number: '24',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 24) : 0 ,
            },
            {
                itemno: 'item25',
                box_number: '25',
                boxsize_t: '12.2',
                boxsize_r: '',
                boxsize_b: '7.0',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 25) : 0 ,
            },
            {
                itemno: 'item25A',
                box_number: '',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 25) : 0 ,
            },
            {
                itemno: 'item25B',
                box_number: '',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 25) : 0 ,
            },
        ],
        layout4: [
            {
                itemno: 'item26',
                box_number: '26',
                boxsize_t: '1.5+8.8',
                boxsize_r: '12.9',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 26) : 0 ,
            },
            {
                itemno: 'item27',
                box_number: '27',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 27) : 0 ,
            },
            {
                itemno: 'item28',
                box_number: '28',
                boxsize_t: '8.7+1.5',
                boxsize_r: '1.5+10.7',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 28) : 0 ,
            },
        ],
        layout5: [
            {
                itemno: 'item29',
                box_number: '29',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '11.7',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 29) : 0 ,
            },
            {
                itemno: 'item30',
                box_number: '30',
                boxsize_t: '',
                boxsize_r: '12.2',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 30) : 0 ,
            },
            {
                itemno: 'item31',
                box_number: '31',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 31) : 0 ,
            },
        ]
    }

    return(
        <>
            {
                data === "true" && <Modal
                show={show}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
        >   
            <Modal.Header closeButton>
                <Modal.Title>Land Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Site Identification No: {modelData?.box_number} <br />
                Site Size Details:{modelData?.boxsize_t} {modelData?.boxsize_r} {modelData?.boxsize_b} {modelData?.boxsize_l}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </Modal>
            }    

                 <div className="container">
                <div className="">
                    <div className='design-7'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design7.layout1.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }                                
                            </div>  
                            <div className='layout-2'>
                                {
                                    design7.layout2.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-3'>
                                {
                                    design7.layout3.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-4'>
                                {
                                    design7.layout4.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                            <div className='layout-5'>
                                {
                                    design7.layout5.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>  
                           
                            <div class="roadcross1"></div>
                            <div class="roadcross2"></div>
                            <div class="roadcross3"></div>
                            <div class="roadcross4"></div>
                            <div class="roadcross5"></div>
                            <div class="roadcross6"></div>
                            <div class="roadcross7"></div>
                                               

                            <div class="road-1">9.0M Layout Road </div>
                            <div class="road-2">10.0M Layout Road </div>
                            <div class="road-3">9.0M Layout Road </div>
                            <div class="road-4">9.0M Layout Road </div>
                            
                            

                            <div class="whitebox1"></div>
                            <div class="whitebox2"></div>
                            <div class="whitebox3"></div>
                            <div class="whitebox4"></div>
                            <div class="whitebox5"></div>
                            <div class="whitebox6"></div>
                            

                            <div class="sf1">54.8M</div>                           
                            <div class="sf2">12.8M</div>                           
                            <div class="sf3">71.4M</div>                           
                            <div class="sf4">60.6M</div>                           
                            <div class="sf5">22.4M</div>                           
                            <div class="sf6">34.8M</div>                           
                            <div class="sf7">21.2M</div>                           
                            <div class="sf8">22.0M</div>                           
                            <div class="sf9">71.6M</div>                           
                            <div class="sf10">15.8M</div>                           
                                                       

                            <div class="sm1">Sathy - Bahavanisagar Main Road</div>                         
                            <div class="sm2">15.0 M Panchayat Road</div>                         
                         
                              


                            <div class="rs1">R.S NO:282/1 A 1</div>                           
                            <div class="rs2">R.S NO:282/1 B</div>                           
                            <div class="rs3">R.S NO:282/2 B </div>                           
                            <div class="rs4">R.S NO:282/3 C</div>                           
                            <div class="rs5">R.S NO:282/3 A 2</div>                           
                            <div class="rs6">R.S NO:282/3 B</div>                           
                            <div class="rs7">R.S NO:283</div>                           
                                                     
                        </div>
                    </div>
                </div>
            </div>    
        </>
    )
}