import { LAYOUT_VIEW, LAYOUT_INSERT, LAYOUT_SUCCESS, LAYOUT_FAIL, LAYOUT_UPDATE, LAYOUT_DELETE  } from  "../type/types";

const initialState = {       
    loading : true,
    payload : [],
    error : {}
}

export const LayoutReducer = ( state = initialState , action ) =>{
    const {type , payload} = action;
    switch(type){
        case LAYOUT_VIEW :
            return{
                ...state,               
                loading:false,
                payload:payload
            }      
        case LAYOUT_FAIL :
            return{
                ...state,               
                loading:false,
                error:payload
            }      
            default:
                return state;

    }

}