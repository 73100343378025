import React from 'react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';


export default function PostProperty() {
    return (
        <>
            <Header />
            <div className="page-wrapper bg-white">
                <div className="container ">
                    <div className="row py-3">
                        <div className="col"> <h3 className='text-black mt-5'>Post your Property</h3></div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 upload-bx-con1">
                                    <div className="upload-box1"></div>
                                </div>
                                <div className="col-lg-6 col-md-12 d-flex upload-bx-con">
                                    <div className="upload-box2"></div>
                                    <div className="upload-box3"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12  add-image-btn-container">
                            <button className='btn btn-disabled ml-2 pl-5 pr-5'>Add Images</button>
                        </div>
                    </div>
                    <div className="row pb-5">
                        <form class="row g-3 property-form pl-5">
                            <div class="col-md-6">
                                <input type="text" class="form-control form-input-holder" placeholder="First name" required />
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control form-input-holder" placeholder="Last name" required />
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control form-input-holder" placeholder="Address Line 1" required />
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control form-input-holder" placeholder="Address Line 2" required />
                            </div>
                            <div class="col-md-6">
                                <input type="url" class="form-control form-input-holder icon-rtl" placeholder="Location" required />
                            </div>
                            <div class="col-md-6">
                                <input type="number" class="form-control form-input-holder" placeholder="Contact Number" required />
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control form-input-holder" id="" rows="5" placeholder='Descripition'></textarea>
                            </div>
                            <div class="col-md-12 text-center">
                                <button type='submit' className='btn btn-disabled pl-5 pr-5'>Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div >
            <Footer />
        </>
    )
}
