import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../../css/design3.css';
import Design1 from '../Layout/Design1';
import Design2 from '../Layout/Design2';
import Design3 from '../Layout/Design3';
import Design4 from '../Layout/Design4';
import Design5 from '../Layout/Design5';
import Design6 from '../Layout/Design6';
import Design7 from '../Layout/Design7';
import Design8 from '../Layout/Design8';


export default function CarouselProperty() {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <div class="carousel-item active">
                        <Design1 data="true" />
                    </div>
                </Carousel.Item  >
                <Carousel.Item>
                    <div class="carousel-item active">
                        <Design2 data="true" />
                    </div>
                </Carousel.Item  >
                <Carousel.Item>
                    <div class="carousel-item active">
                        <Design3 data="true" />
                    </div>
                </Carousel.Item  >
                <Carousel.Item> 
                    <div class="carousel-item active">
                        <Design4 data="true" />
                    </div>
                </Carousel.Item  >              
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design5 data="true" />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design6 data="true" />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design7 data="true"/>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design8 data="true"/>
                    </div>
                </Carousel.Item>
            </Carousel>
        </>
    )
}
