import React from 'react'

import arrowupicon from '../../../images/Dashboard/arrowup.png'
import barcharticon from '../../../images/Dashboard/bar_chart.png'
import chartrevenue from '../../../images/Dashboard/chartrevenue.png'
import arrowdownicon from '../../../images/Dashboard/downarrow.png'
import soldchartstaff from '../../../images/Dashboard/soldchartstaff.png'


export default function Dashboard() {
    return (
        <>
            <div className="dashboard-grid-container mt-lg-1">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Total Listings</p>
                            <p className='total-listings-no'>1,285</p>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <img src={arrowupicon} alt="arrow" /> <span style={{ color: '#64C874' }} >6,3%</span>
                                </div>
                                <span style={{ color: '#D0D2D3' }}>Plots</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Sold</p>
                            <p className='total-listings-no'>136</p>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <img src={arrowupicon} alt="arrow" /> <span style={{ color: '#64C874' }}>2,3%</span>
                                </div>
                                <span style={{ color: '#D0D2D3' }}>Plots</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Payments</p>
                            <p className='total-listings-no'>₹25000</p>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <img src={arrowdownicon} alt="arrow" /> <span style={{ color: '#ED6F6F' }}>2,3%</span>
                                </div>
                                <span style={{ color: '#D0D2D3' }}>Payments</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-grid-container mt-lg-1">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Land Owners</p>
                            <p className='total-listings-no'>1,285</p>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <img src={arrowupicon} alt="arrow" /> <span style={{ color: '#64C874' }} >6,3%</span>
                                </div>
                                <span style={{ color: '#D0D2D3' }}>Land Owners</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Total Payments</p>
                            <p className='total-listings-no'>₹25000</p>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <img src={arrowupicon} alt="arrow" /> <span style={{ color: '#64C874' }}>2,3%</span>
                                </div>
                                <span style={{ color: '#D0D2D3' }}>Payments</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Unapproved</p>
                            <p className='total-listings-no'>50</p>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <img src={arrowdownicon} alt="arrow" /> <span style={{ color: '#ED6F6F' }}>250</span>
                                </div>
                                <span style={{ color: '#D0D2D3' }}>Unapproved</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='weekly-revenue-grid-container'>
                <div className="row">
                    <div className="col-lg-7 col-md-7">
                        <div className='weekly-revenue-col'>
                            <div className='d-flex justify-content-between '>
                                <span className='weeklyrevenue-word'>Weekly Revenue</span>
                                <img src={barcharticon} alt="barcharticon" className='barcharticon' />
                            </div>
                            <img src={chartrevenue} alt="chartrevenue" className='img-fluid d-block mx-auto chartrevenue-img' />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5">
                        <div className='solproperty-col'>
                            <div className='sold-property-row1 d-flex justify-content-around'>
                                <span className='sold-percentage'>Sold Property</span>
                                <select class="no-border" style={{ color: '#A3AED0' }} >
                                    <option value="1" selected="selected">Monthly</option>
                                    <option value="2">Weekly</option>
                                    <option value="3">Daily</option>
                                </select>
                            </div>
                            <img src={soldchartstaff} alt="soldchart" className='img-fluid d-block mx-auto soldchart' width={'25%'} />
                            <div className='d-flex justify-content-around p-md-3'>
                                <div>
                                    <span className='soldcircle-dot'></span>
                                    <span className='soldchart-word'>Sold</span> <br />
                                    <span className='sold-percentage ml-4'>63%</span>
                                </div>
                                <div>
                                    <span className='unsoldcircle-dot'></span>
                                    <span className='soldchart-word'>Unsold</span> <br />
                                    <span className='sold-percentage ml-4'>25%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}



