import { BIND_SUCCESS, BIND_FAIL, STAFF_INSERT , STAFF_UPDATE, STAFF_DELETE  } from  "../type/types";

const initialState = {       
    loading : true,
    payload : [],
    error : {}
}

export const BindReducer = ( state = initialState , action ) =>{
    const {type , payload} = action;
    switch(type){
        case BIND_SUCCESS :
            return{
                ...state,               
                loading:false,
                payload:payload
            }      
        case BIND_FAIL :
            return{
                ...state,               
                loading:false,
                error:payload
            }      
            default:
                return state;

    }

}