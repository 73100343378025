import React from 'react'

function PaymentDetails({ pageData }) {
    console.log(pageData)
    return (
        <>
            <div className='listings-heading'>Payment Details</div>
            <div className='paymentdetails-table'>
                <div className="row paymentdetails-row1">
                    <div className="col-lg-6 paymentdetails-row1-col1">
                        <span className='paymentdetails-tableheading' >VS Promoters  </span> <span> <br />1234 State St <br />San Francisco, CA, 12345</span>
                    </div>
                    <div className="col-lg-6 paymentdetails-row1-col2">
                        <span>Sales <br />#101020</span>
                    </div>
                </div>
                <hr style={{ color: '#000000', backgroundColor: '#000000', height: 1, borderColor: '#000000', opacity: 0.2, }} />
                <div className="row paymentdetails-row2">
                    <div className="col-lg-3">
                        <span className='paymentdetails-tableheading'>Client Contact Info: </span><span> <br />Apple <br /> Tim Cook <br />tim@apple.com</span>
                    </div>
                    <div className="col-lg-3">
                        <span className='paymentdetails-tableheading'> Bill To: <br /> </span><span> Tim Cook <br /> 6789 Mac Ln <br /> Palo Alto, CA, 54321</span>
                    </div>
                    <div className="col-lg-3 paymentdetails-row2-col3">
                        <span>Client Name <br /> Issue Date <br /> Due Date <br />Amount Due </span>
                    </div>
                    <div className="col-lg-3 paymentdetails-row2-col4">
                        <span> VS <br /> 09.10.20 <br /> 10.10.20 <br /> $10,000.00 </span>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PaymentDetails