import React, { useState } from "react";

// Icons

import arrowicon from '../../../images/Dashboard/arrowicon.png';
import Dashboardicon from "../../../images/Dashboard/dashboardicon.png";
import Dashboardwhite from '../../../images/Dashboard/dashboardwhite.png';
import Listingicon from '../../../images/Dashboard/listingicon.png';
import Listingiconwhite from '../../../images/Dashboard/listingicon3.png';
import Logouticon2 from '../../../images/Dashboard/logouticon2.png';
import Profileicon from '../../../images/Dashboard/profileicon.png';
import Profileiconwhite from '../../../images/Dashboard/profileicon2.png';
import Rupeeicon from '../../../images/Dashboard/rupeeicon.png';
import Rupeeiconwhite from '../../../images/Dashboard/rupeeicon2.png';
import Supporticon from '../../../images/Dashboard/supporticon.png';
import usericon from '../../../images/Dashboard/usericonstaff.png';
import Userprofileblack from '../../../images/Dashboard/userprofileblack.png';
import Userprofilewhite from '../../../images/Dashboard/userprofilewhite.png';
import Usersiconblack from '../../../images/Dashboard/usersiconblack.png';
import Usersiconwhite from '../../../images/Dashboard/usersiconwhite.png';


// Pages

import Addlistings from "./Addlistings";
import Addlistings2 from "./Addlistings2";
import Dashboard from "./Dashboard";
import Listings from "./Listings";
import LandownerListings from './ListingsCopy';
import PaymentDetails from "./PaymentDetails";
import Payments from "./Payments";
import Profile from "./Profile";


export default function NavPage() {

    const [dashboard, setDashboard] = useState(true);
    const [listings, setListings] = useState(false);
    const [owners, setOwners] = useState(false);
    const [payments, setPayments] = useState(false);
    const [users, setUsers] = useState(false);
    const [userprofile, setUserprofile] = useState(false);
    const [addListing, setAddListing] = useState(false);
    const [payemntdetails, setPayemntdetails] = useState(false);
    const [uploadFiles, setUploadFiles] = useState(false);
    const [pageListData, setPageListData] = useState([]);
    const [listingPage, setListingPage] = useState(true);



    const dashboardHandler = () => {
        setDashboard(true);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const listingsHandler = () => {
        setDashboard(false);
        setListings(true);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const ownerHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(true);
        setAddListing(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const paymentsHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(true);
        setUsers(false);
        setUserprofile(false);
        setAddListing(false);
        setPayemntdetails(false)
        setUploadFiles(false);
    }
    const usersHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(true);
        setUserprofile(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    }
    const userprofileHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(true);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    }


    const paymentdetailsData = (page, data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(false);
        setAddListing(false);
        setPayemntdetails(true)
        setUploadFiles(false);
    }

    const addListingData = (page, data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(false);
        setAddListing(true);
        setPayemntdetails(false)
        setPageListData(page);
        setUploadFiles(false);
        setListingPage(true)
    }

    const moveListingData = (data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(false);
        setAddListing(true);
        setPayemntdetails(false)
        setPageListData(data);
        setUploadFiles(false);
        setListingPage(false)

    }

    const uploadImagePageMove = (page, data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setUserprofile(false);
        setAddListing(false);
        setPayemntdetails(false)
        setUploadFiles(true);
    }

    return (
        <>
            <div className="row staff">
                <div className="col-lg-2 col-md-2 col-sm-1 navigation-row">
                    <div class="useruploadicon-wrapper">
                        <img src={usericon} className='useruploadicon' alt='usericon' />
                        <input type="file" />
                    </div>
                    <div className="">
                        <p className="username-dashboard">KARTHIK T</p>
                        <p className="usermailid-dashboard">karthikt@gmail.com</p>
                    </div>

                    <div>
                        <div className="row navpage-icon-container pt-1">
                            <div className={dashboard === true ? "col-lg-6 col-md-12 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 text-center landowner-menu-col1"} onClick={dashboardHandler}>
                                <div>
                                    {dashboard === true ? <img src={Dashboardwhite} className='Landowners-icon' alt='dashboard' /> : <img src={Dashboardicon} alt='dashboard' />}</div> <div className="icondashboard-word">Dashboard</div>
                            </div>
                            <div className={listings === true ? "col-lg-6 col-md-12 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 text-center  landowner-menu-col2"} onClick={listingsHandler} >
                                <div>
                                    {listings === true ? <img src={Listingiconwhite} className='Landowners-icon' alt='listings' /> : <img src={Listingicon} alt='dashboard' />}</div>
                                <div className="">Listings</div>
                            </div>

                        </div>
                        <div className="row navpage-icon-container2">
                            <div className={payments === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col3"} onClick={paymentsHandler}>
                                <div>
                                    {payments === true ? <img src={Rupeeiconwhite} className='Landowners-icon' alt='owners' /> : <img src={Rupeeicon} alt='owners' />} </div> <div className="icondashboard-word">Payments</div>
                            </div>
                            <div className={owners === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12  col-sm-3 text-center landowner-menu-col4"} onClick={ownerHandler}>
                                <div>
                                    {owners === true ? <img src={Profileiconwhite} className='Landowners-icon' alt='owners' /> : <img src={Profileicon} alt='owners' />} </div> <div className="">Owners</div>
                            </div>
                        </div>
                        <div className="row navpage-icon-container2">
                            <div className={users === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col3"} onClick={usersHandler}>
                                <div>
                                    {users === true ? <img src={Usersiconwhite} className='Landowners-icon' alt='owners' /> : <img src={Usersiconblack} alt='owners' />} </div> <div className="icondashboard-word">Users</div>
                            </div>
                            <div className={userprofile === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12  col-sm-3 text-center landowner-menu-col4"} onClick={userprofileHandler}>
                                <div>
                                    {userprofile === true ? <img src={Userprofilewhite} className='Landowners-icon' alt='owners' /> : <img src={Userprofileblack} alt='owners' />} </div> <div className="icondashboard-word">Profile</div>
                            </div>
                        </div>
                    </div>
                    <div className="row logout-container">
                        <div className="col-lg-3 col-md-3"> <img src={Logouticon2} className='logout-icon2' alt='logout' /></div>
                        <div className="col-lg-5 col-md-3"><p className="logout-word">Logout</p></div>
                        <div className="col-lg-3 col-md-3"><img src={arrowicon} alt='arow' /></div>
                    </div>
                    <div className="row help-container">
                        <div className="col-lg-3 col-md-3"><img src={Supporticon} className='logout-icon2' alt='support' /></div>
                        <div className="col-lg-5 col-md-3"><p className="logout-word">Help</p></div>
                        <div className="col-lg-3 col-md-3"><img src={arrowicon} alt='arow' /></div>
                    </div>
                </div>
                <div className="col-lg-10 col-md-10 navigation-container" style={{ flex: 1, backgroundColor: "white", borderRadius: '20px' }}>
                    {dashboard && (
                        <Dashboard />
                    )}
                    {listings && (
                        <Listings addListing={addListingData} viewListingData={moveListingData} />
                    )}
                    {addListing && (
                        <Addlistings pageData={pageListData} uploadPage={uploadImagePageMove} pageMove={listingPage} />
                    )}
                    {owners && (
                        <LandownerListings addListing={addListingData} viewListingData={moveListingData} />

                    )}
                    {payments && (
                        <Payments paymentdetails={paymentdetailsData} />
                    )}
                    {users && (
                        <LandownerListings addListing={addListingData} viewListingData={moveListingData} />
                    )}
                    {userprofile && (
                        <Profile />
                    )}
                    {payemntdetails && (
                        <PaymentDetails />
                    )}
                    {uploadFiles && (
                        <Addlistings2 />
                    )}
                </div>
            </div>
        </>
    )
}

