import React from 'react';


// Images & icons
import arrowright from '../../../images/Dashboard/arrowright.png';
import filtericon from '../../../images/Dashboard/filtericon.png';
import listingicon2 from '../../../images/Dashboard/listingicon2.png';


export default function ListingData({ addListing, viewListingData }) {

    const listings = [
        {
            sitestreet: 'RM Nagar',
            district: 'Erode',
            sqft: '100',
            amount: '₹420.84',
            date: '14 Apr 2022',
            time: 'at 8:00 PM',
            siteid: 'MGL0124877',

        },
        {
            sitestreet: 'VIN Nagar',
            district: 'Erode',
            sqft: '500',
            amount: '₹100.00',
            date: '05 Apr 2022',
            time: 'at 7:00 PM',
            siteid: 'MGL0124585'
        },
        {
            sitestreet: 'SS Street',
            district: 'Salem',
            sqft: '200',
            amount: '₹244.20',
            date: '02 Apr 2022',
            time: 'at 10:00 PM',
            siteid: 'MGL0124124'
        },
        {
            sitestreet: 'New Steel Promoters',
            district: 'Salem',
            sqft: '1000',
            amount: '₹520.84',
            date: '02 Apr 2022',
            time: 'at 6:00 PM',
            siteid: 'MGL0128544'
        },
        {
            sitestreet: 'SRC Promoters',
            district: 'Madhurai',
            sqft: '150',
            amount: '₹500.10',
            date: '30 Mar 2022',
            time: 'at 9:00 PM',
            siteid: 'MGL0122143'
        },
        {
            sitestreet: 'MGR Nagar',
            district: 'Erode',
            sqft: '369',
            amount: '₹100.00',
            date: '20 Mar 2022',
            time: 'at 9:00 PM',
            siteid: 'MGL0124877'
        },
        {
            sitestreet: 'KK Street',
            district: 'Salem',
            sqft: '850',
            amount: '₹584.00',
            date: '24 Mar 2022',
            time: 'at 8:00 PM',
            siteid: 'MGL0124244'
        },
        {
            sitestreet: 'Agil Medu',
            district: 'Chennai',
            sqft: '2500',
            amount: '₹400.11',
            date: '10 Mar 2022',
            time: 'at 7:00 AM',
            siteid: 'MGL0127749'
        },

    ]

    return (
        <>
            <span className='listings-heading'>Listings</span>
            <div className='d-flex flex-wrap justify-content-between mt-4 ml-lg-5 mr-lg-5'>
                <div class="form-group has-search-listings">
                    <span class="fa fa-search form-control-listings"></span>
                    <input type="text" class="form-control" placeholder="Search anything on Transactions" />
                </div>
                <div >
                    <button className='btn addlistings-cus-btn mr-lg-5 mr-md-3' onClick={() => addListing(true, "Add List")}> <img src={listingicon2} alt="" className='' /> <span style={{ fontWeight: 'bolder' }}>Add Listings</span></button>
                    <button className='btn filters-cus-btn'> <img src={filtericon} alt="" className='' /> <span style={{ fontWeight: 'bolder' }}>Filters</span> </button>
                </div>
            </div>
            <div className='table-listings'>
                <table class="table-responsive-md">
                    <thead>
                        <tr className='table-listings-heading'>
                            <th scope="col">Place</th>
                            <th scope="col">Sqft</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date</th>
                            <th scope="col">ID</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listings.map((item, index) => (<tr key={index}>
                            <td scope='col'>
                                <span className='sitestreet-listings'> {item.sitestreet}</span><br />
                                <span className='district-listings'>{item.district} </span>
                            </td>
                            <td scope='col'>
                                <span className='sqft-listings'>{item.sqft}</span>
                            </td>
                            <td scope='col'>
                                <span className='amount-listings'> {item.amount} </span>
                            </td>
                            <td scope='col'>
                                <span className='date-listings' > {item.date} </span><br />
                                <span className='time-listings'> {item.time} </span>
                            </td>
                            <td scope='col'>
                                <span className='siteid-listings'> {item.siteid} </span>
                            </td>
                            <td scope='col' className=''>
                                <button className='btn custombtn-listings' onClick={() => viewListingData(item)} >View</button>
                            </td>
                        </tr>))}
                    </tbody>
                </table>
            </div>

            <div className='d-flex flex-row-reverse next-btn-container mt-lg-3'>
                <button className='btn next-cus-btn'>Next <img src={arrowright} alt="" /></button>
            </div>
        </>
    )
}

