import React, { useState } from "react";

// Icons

import arrowicon from '../../../images/Dashboard/arrowicon.png';
import Dashboardicon from "../../../images/Dashboard/dashboardicon.png";
import Dashboardwhite from '../../../images/Dashboard/dashboardwhite.png';
import frontendblack from '../../../images/Dashboard/frontendblack.png';
import frontendwhite from '../../../images/Dashboard/frontendwhite.png';
import Listingicon from '../../../images/Dashboard/listingicon.png';
import Listingiconwhite from '../../../images/Dashboard/listingicon3.png';
import Logouticon2 from '../../../images/Dashboard/logouticon2.png';
import Profileicon from '../../../images/Dashboard/profileicon.png';
import Profileiconwhite from '../../../images/Dashboard/profileicon2.png';
import Rupeeicon from '../../../images/Dashboard/rupeeicon.png';
import Rupeeiconwhite from '../../../images/Dashboard/rupeeicon2.png';
import settingsblack from '../../../images/Dashboard/settingsblack.png';
import settingswhite from '../../../images/Dashboard/settingswhite.png';
import staffblack from '../../../images/Dashboard/staffblack.png';
import staffwhite from '../../../images/Dashboard/staffwhite.png';
import Supporticon from '../../../images/Dashboard/supporticon.png';
import usericonadmin from '../../../images/Dashboard/usericonadmin.png';
import Usersiconblack from '../../../images/Dashboard/usersiconblack.png';
import Usersiconwhite from '../../../images/Dashboard/usersiconwhite.png';
import BindState from '../Admin/BindState';
import BindDistrict from '../Admin/BindDistrict';
import BindBlock from '../Admin/BindBlock';
import BindVillage from '../Admin/BindVillage';

// Pages

import AdminfrontEnd from '../Admin/Frontend';
import StaffAdd from "../Admin/StaffAdd";
import Staffpage from "../Admin/Staffpage";
import ListingDataTable from '../ListingData/ListingDataTable';
import Addlistings from "./Addlistings";
import Addlistings2 from "./Addlistings2";
import Dashboard from "./Dashboard";
import Listings from "./Listings";
import LandownerListings from './ListingsCopy';
import PaymentDetails from "./PaymentDetails";
import Payments from "./Payments";
import Setting1 from "./Setting1";


export default function NavPage() {

    const [dashboard, setDashboard] = useState(true);
    const [listings, setListings] = useState(false);
    const [owners, setOwners] = useState(false);
    const [payments, setPayments] = useState(false);
    const [users, setUsers] = useState(false);
    const [settings, setSettings] = useState(false);
    const [staff, setStaff] = useState(false);   
    const [staffAdd, setStaffAdd] = useState(false);
    const [frontend, setfrontend] = useState(false);
    const [addListing, setAddListing] = useState(false);
    const [payemntdetails, setPayemntdetails] = useState(false);
    const [uploadFiles, setUploadFiles] = useState(false);
    const [pageListData, setPageListData] = useState([]);
    const [listingPage, setListingPage] = useState(true);



    const dashboardHandler = () => {
        setDashboard(true);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setSettings(false);
        setStaff(false);
        setStaffAdd(false);
        setfrontend(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const listingsHandler = () => {
        setDashboard(false);
        setListings(true);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setStaff(false);
        setStaffAdd(false);
        setfrontend(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const ownerHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(true);
        setAddListing(false);
        setPayments(false);
        setStaff(false);
        setStaffAdd(false);
        setfrontend(false);
        setUsers(false);
        setSettings(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    };
    const paymentsHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(true);
        setfrontend(false);
        setStaff(false);
        setStaffAdd(false);
        setUsers(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false)
        setUploadFiles(false);
    }
    const usersHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setfrontend(false);
        setStaff(false);
        setStaffAdd(false);
        setUsers(true);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    }
    const settingsHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setfrontend(false);
        setStaff(false);
        setStaffAdd(false);
        setUsers(false);
        setSettings(true);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    }

    const frontendHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setfrontend(true);
        setStaff(false);
        setStaffAdd(false);
        setUsers(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    }

    const staffHandler = () => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setfrontend(false);
        setStaff(true);
        setStaffAdd(false);
        setUsers(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false);
        setUploadFiles(false);
    }
    const paymentdetailsData = (page, data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setfrontend(false);
        setStaff(false);
        setUsers(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(true);
        setUploadFiles(false);
        setStaffAdd(false);
    }

    const addListingData = (page, data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setfrontend(false);
        setStaff(false);
        setSettings(false);
        setAddListing(true);
        setPayemntdetails(false);
        setPageListData(page);
        setUploadFiles(false);
        setListingPage(true);
        setStaffAdd(false);

    }

    const moveListingData = (data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setfrontend(false);
        setStaff(false);
        setSettings(false);
        setAddListing(true);
        setPayemntdetails(false);
        setPageListData(data);
        setUploadFiles(false);
        setListingPage(false);
        setStaffAdd(false);

    }
    const addStaffData = (page, data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setfrontend(false);
        setStaff(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false);
        setPageListData(page);
        setUploadFiles(false);
        setListingPage(false);
        setStaffAdd(true);

    }

    const moveStaffAdd = (data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setUsers(false);
        setfrontend(false);
        setStaff(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false);
        setPageListData(data);
        setUploadFiles(false);
        setListingPage(false);
        setStaffAdd(true);

    }

    const uploadImagePageMove = (page, data) => {
        setDashboard(false);
        setListings(false);
        setOwners(false);
        setPayments(false);
        setfrontend(false);
        setStaff(false);
        setUsers(false);
        setSettings(false);
        setAddListing(false);
        setPayemntdetails(false)
        setUploadFiles(true);
        setStaffAdd(false);
    }

    

    return (
        <>
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-1 navigation-row">
                    <div class="useruploadicon-wrapper adminicon-wrapper">
                        <img src={usericonadmin} className='ml-lg-5 useruploadicon' alt='usericon' />
                        <input type="file" />
                    </div>
                    <div>
                        <p className="username-dashboard-admin">SRC ADMIN</p>
                        <p className="usermailid-dashboard">srclandpromoters@gmail.com</p>
                    </div>

                    <div>
                        <div className="row navpage-icon-container2">
                            <div className={dashboard === true ? "col-lg-6 col-md-12 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 text-center landowner-menu-col1"} onClick={dashboardHandler}>
                                <div>
                                    {dashboard === true ? <img src={Dashboardwhite} className='Landowners-icon' alt='dashboard' /> : <img src={Dashboardicon} alt='dashboard' />}</div> <div className="icondashboard-word">Dashboard</div>
                            </div>
                            <div className={listings === true ? "col-lg-6 col-md-12 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 text-center  landowner-menu-col2"} onClick={listingsHandler} >
                                <div>
                                    {listings === true ? <img src={Listingiconwhite} className='Landowners-icon' alt='listings' /> : <img src={Listingicon} alt='dashboard' />}</div>
                                <div className="">Listings</div>
                            </div>

                        </div>
                        <div className="row navpage-icon-container2">
                            <div className={payments === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col3"} onClick={paymentsHandler}>
                                <div>
                                    {payments === true ? <img src={Rupeeiconwhite} className='Landowners-icon' alt='owners' /> : <img src={Rupeeicon} alt='owners' />} </div> <div className="icondashboard-word">Payments</div>
                            </div>
                            <div className={owners === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12  col-sm-3 text-center landowner-menu-col4"} onClick={ownerHandler}>
                                <div>
                                    {owners === true ? <img src={Profileiconwhite} className='Landowners-icon' alt='owners' /> : <img src={Profileicon} alt='owners' />} </div> <div className="">Owners</div>
                            </div>
                        </div>
                        <div className="row navpage-icon-container2">
                            <div className={users === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col3"} onClick={usersHandler}>
                                <div>
                                    {users === true ? <img src={Usersiconwhite} className='Landowners-icon' alt='owners' /> : <img src={Usersiconblack} alt='owners' />} </div> <div className="icondashboard-word">Users</div>
                            </div>
                            <div className={staff === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12  col-sm-3 text-center landowner-menu-col4"} onClick={staffHandler}>
                                <div>
                                    {staff === true ? <img src={staffwhite} className='Landowners-icon' alt='owners' /> : <img src={staffblack} alt='owners' />} </div> <div className="icondashboard-word">Staff</div>
                            </div>
                        </div>
                        <div className="row navpage-icon-container2">
                            <div className={frontend === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col3"} onClick={frontendHandler}>
                                <div>
                                    {frontend === true ? <img src={frontendwhite} className='Landowners-icon' alt='owners' /> : <img src={frontendblack} alt='owners' />} </div> <div className="icondashboard-word">Front End</div>
                            </div>
                            <div className={settings === true ? "col-lg-6 col-md-12 col-sm-3 text-center landowner-menu-col-active" : "col-lg-6 col-md-12  col-sm-3 text-center landowner-menu-col4"} onClick={settingsHandler}>
                                <div>
                                    {settings === true ? <img src={settingswhite} className='Landowners-icon' alt='owners' /> : <img src={settingsblack} alt='owners' />} </div> <div className="icondashboard-word">Settings</div>
                            </div>
                        </div>
                    </div>
                    <div className="logouthelp-container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3"> <img src={Logouticon2} className='logout-icon2' alt='logout' /></div>
                            <div className="col-lg-5 col-md-3"><p className="logout-word">Logout</p></div>
                            <div className="col-lg-3 col-md-3"><img src={arrowicon} alt='arow' /></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3"><img src={Supporticon} className='logout-icon2' alt='support' /></div>
                            <div className="col-lg-5 col-md-3"><p className="logout-word">Help</p></div>
                            <div className="col-lg-3 col-md-3"><img src={arrowicon} alt='arow' /></div>
                        </div>
                    </div>

                </div>
                <div className="col-lg-10 col-md-10 navigation-container" style={{ flex: 1, backgroundColor: "white", borderRadius: '20px' }}>
                    {dashboard && (
                        // <Dashboard />
                        // <StaffAdd />
                        // <ViewlandOwners />
                        // <AdminfrontEnd />
                        // <Setting1 />
                        < ListingDataTable />
                    )}
                    {listings && (
                        <Listings addListing={addListingData} viewListingData={moveListingData} />

                    )}
                    {addListing && (
                        <Addlistings pageData={pageListData} uploadPage={uploadImagePageMove} pageMove={listingPage} />
                    )}
                    {owners && (
                        <LandownerListings addListing={addListingData} viewListingData={moveListingData} />

                    )}
                    {payments && (
                        <Payments paymentdetails={paymentdetailsData} />
                    )}
                    {users && (
                        <LandownerListings addListing={addListingData} viewListingData={moveListingData} />
                    )}
                    {settings && (
                        <Setting1 />
                    )}
                    {payemntdetails && (
                        <PaymentDetails />
                    )}
                    {uploadFiles && (
                        <Addlistings2 />
                    )}
                    {frontend && (
                        <AdminfrontEnd />
                    )}
                    {staff && (
                        <BindDistrict addStaff={addStaffData} viewStaffData={moveStaffAdd} />
                    )}
                    {/* {bind && (
                        <BindReducer addStaff={addStaffData} viewStaffData={moveStaffAdd} />
                    )} */}
                    {staffAdd && (
                        <StaffAdd />
                    )}
                </div>
            </div>
        </>
    )
}

