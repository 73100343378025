import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RecentListing2 from '../Element/RecentListing2';
import Footer from './../Layout/Footer';
import Header from './../Layout/Header';

var bnr1 = require('./../../images/aboutus/bnr1.png');
var bnr2 = require('./../../images/aboutus/bnr2.png');
var call = require('./../../images/aboutus/call.png');
var tele = require('./../../images/aboutus/tele.png');
var whatsapp = require('./../../images/aboutus/whatsapp.png');
var icon1 = require('./../../images/aboutus/icon1.png');
var mission = require('./../../images/aboutus/mission.png');
var vission = require('./../../images/aboutus/vission.png');
var callus = require('./../../images/contacts/callus.png');






function Aboutus() {
	const [show, setShow] = useState(false);
	return (
		<div className="page-wraper bg-white">
			<Header />

			<div className="page-content1">
				<div className="content-block1">
					<div className="container mt-5">
						<h2>My Profile</h2>
						<div className="row align-items-center m-b50">
							<div className="col-lg-4 col-md-12 text-center">
								<img src={bnr1} alt="" className='img-fluid' />
							</div>
							<div className="col-lg-6 col-md-6 col-sm-10 pt-3 profile-details">
								<div className="row"><h4>Kristin Watson</h4> </div>
								<div className="row"> <p> Designation</p> </div>
								<div className="row">
									<div className="col d-flex">
										<img src={call} alt="" className='img-telephone' />
										<p>+123 456 789 000</p>
									</div>
								</div>
								<div className="row">
									<div className="col d-flex">
										<img src={tele} alt="" className='img-telephone' />
										<p>mail.urmail.com</p>
									</div>
								</div>
								<div className="row">
									<div className="col d-flex">
										<img src={whatsapp} alt="" className='img-telephone' />
										<p>+00 123 456 789</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-6 col-sm-2 text-center">
								<Button variant="primary" onClick={() => setShow(true)}>
									Edit Profile
								</Button>
							</div>
						</div>
						<div className='text-justify'>
							<h3>About Kristin Watson</h3>
							<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
							<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form by injected humour.</p>
						</div>
						<RecentListing2 />
					</div>
					<div className="content-block2 container mt-5">
						<div className="row">
							<div className="col-lg-5 text-center">
								<img src={bnr2} alt="" width={350} className='img-fluid mb-5 ' />
							</div>
							<div className="col-lg-6 text-justify">
								<div className="row">
									<div className="col-1">
										<img src={icon1} alt="" />
									</div>
									<div className="col">
										<p>Know More About Us</p>
									</div>
								</div>
								<h3>Are You Looking Best Property Near You? Contact Us</h3>
								<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking layout.</p>
								<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
								<div className="row call-us-bx mb-5 mx-auto">
									<div className="col-3 my-3">
										<img src={callus} alt="" className='img-fluid call-us-image' />
									</div>
									<div className="col-8 text-white my-2">
										<p>Call Us now <br />
											<span style={{ fontSize: '18px', fontWeight: 'bolder' }}>+00 123 456 789</span></p>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div className="container mb-5">
						<div className="row small-box-row">
							<div className="col-lg-5 col-md-5 col-sm-8 small-box mb-5 ">
								<div className="row mb-3">
									<div className="col-md-4 col-lg-2">
										<img src={mission} alt="" className='img-fluid mission-vission-icon' width={50} />
									</div>
									<div className="col-md-8 col-lg-10 pt-2">
										Our Mission
									</div>
								</div>
								<div className="row">
									<div className="col">
										<p>It is a long established fact that a reader will be too distracted by the readable content of a page when one looking at its layout.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-5 col-md-5 col-sm-8 small-box mb-5">
								<div className="row mb-3">
									<div className="col-md-4 col-lg-2">
										<img src={vission} alt="" className='img-fluid mission-vission-icon' width={50} />
									</div>
									<div className="col-md-8 col-lg-10 pt-2">
										Our Vission
									</div>
								</div>
								<div className="row">
									<div className="col">
										<p>It is a long established fact that a reader will be too distracted by the readable content of a page when one looking at its layout.</p>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<Footer />

			{/* //Edit Profile model start */}
			<Modal show={show} onHide={() => setShow(false)} size="lg" centered>

				<Modal.Header className='model-header'>
					<button closeButton type="button" class="custom-close-btn-model" aria-label="Close"> Close</button>
				</Modal.Header>
				<Modal.Body className='model-body-edit-profile'>
					<div className="form-input-con">
						<form >
							<div className="form-row">
								<div className="form-group col-md-6">
									<label for="name">Full Name</label>
									<input type="text" className="form-control" id="" placeholder="Full Name" />
								</div>
								<div className="form-group col-md-6">
									<label for="address">Address</label>
									<input type="text" className="form-control" id="" placeholder="New Address" />
								</div>

							</div>
							<div className="form-row">
								<div className="form-group col-md-6">
									<label for="email">Email</label>
									<input type="email" className="form-control" id="" placeholder="New E-Mail ID" />
								</div>
								<div className="form-group col-md-6">
									<label for="number">Mobile Number</label>
									<input type="number" className="form-control" id="" placeholder="New Mobile Number" />
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-6">
									<label for="password">New Password</label>
									<input type="password" className="form-control" id="" placeholder="New Password" />
								</div>
								<div className="form-group col-md-6">
									<label for="name">Confirm Number</label>
									<input type="number" className="form-control" id="" placeholder="Confirm Number" />
								</div>
							</div>
							<div className="form-row ">
								<div className="col text-center">
									<button type="submit" className="btn  justify-content-centerbtn-primary w-5">Save Changes</button>
								</div>
							</div>

						</form>
					</div>

				</Modal.Body>
			</Modal>

		</div >
	)

}
export default Aboutus;