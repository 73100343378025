import React, { useState } from 'react';

export default function Setting1() {
    const [editProfile, setEditPrfile] = useState(false)

    function editProfileHandler() {
        setEditPrfile(true);
    };

    function Profileinfoheading() {
        return (
            <span className='profileinfo-heading'>Personal Information</span>
        );
    }

    function Editinfoheading() {
        return (
            <span className='profileinfo-heading'>Edit Information</span>
        );
    }

    return (
        <>
            <div className='m-4'>
                {editProfile === true ? <Editinfoheading /> : <Profileinfoheading />} <br />
                <span>Update your account information</span>
            </div>

            <form className='profile-form-container'>
                <div class="form-row">
                    <div class="form-group form-group-addlistings col-md-6 pr-lg-5">
                        <label for="profile1name">First Name</label>
                        <input type="text" class="form-control border rounded-3" id="streetname" value="VASANTHSENAN" />
                    </div>
                    <div class="form-group col-md-6 pl-lg-5">
                        <label for="profile2name">Last</label>
                        <input type="text" class="form-control border rounded-3" id="numberofplots" value="M" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-lg-5">
                        <label for="dob">Date of Birth</label>
                        <input type="date" class="form-control border rounded-3" id="dob" value="27-09-1998" />
                    </div>
                    <div class="form-group col-md-6 pl-lg-5">
                        <label for="dob">Mobile Number</label>
                        <input type="text" class="form-control border rounded-3 " id="mobile" value="+123 456 7890" />
                    </div>
                </div>
                <div class="form-group has-search-email">
                    <label for="email">Email</label>
                    <i class="fa fa-envelope-o form-control-email" aria-hidden="true"></i>
                    <input type="text" class="form-control" value="vasanth@gmail.com" />
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-lg-5 has-search-email">
                        <label for="dob">New Password</label>
                        <div class="has-password-con">
                            <span class="form-element-profile">
                                <i class="fa fa-lock form-control-email" aria-hidden="true"></i>
                                <span class="fa fa-eye"></span>
                                <input value="**********" />
                            </span>
                        </div>
                    </div>
                    <div class="form-group col-md-6 pl-lg-5 has-search-email">
                        <label for="dob">Confirm Password</label>
                        <div class="has-password-con">
                            <span class="form-element-profile">
                                <i class="fa fa-lock form-control-email" aria-hidden="true"></i>
                                <span class="fa fa-eye"></span>
                                <input value="**********" />
                            </span>
                        </div>
                    </div>
                </div>
            </form>
            <div className='d-flex justify-content-end mr-lg-5 pr-lg-5'>
                <button class="btn addlistings-cus-btn font-weight-bold px-3" onClick={() => editProfileHandler()}>
                    {editProfile === true ? 'Save' : 'Edit'}</button>
            </div>

        </>
    )
}
