import axios from 'axios';
import { STAFF_SUCCESS, STAFF_FAIL, STAFF_INSERT , STAFF_UPDATE, STAFF_DELETE } from  "../type/types";
import { Url, getTokenLocalStorage } from '../../utils/Common';
import swal from 'sweetalert';

// Otp Verification
export const getStaffRegistrationData = () => async dispatch =>{
    const token  = getTokenLocalStorage();
    const formData = {
        action: "View",       
        registerType: 1
    }  
    const headers = {"x-auth-header" : `${token}` }         
    axios.post(`${Url}member`,formData , { headers } ).then((response)=>{     
        dispatch({
            payload : response,
            type : STAFF_SUCCESS
        })      
    }).catch((err)=>{                  
        dispatch({
            payload : err.response,
            type : STAFF_FAIL
        })
    })   
}
