import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

var bnr1 = require('./../../images/main-slider/slide2.png');

class IndexBanner extends Component {
	componentDidMount() {
		var i = 0;
		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea');
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function (event) {
				return this.parentElement.parentElement.classList.add("focused");
			});
		}
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function (event) {
				var inputValue = this.value;
				if (inputValue === '') {
					this.parentElement.parentElement.classList.remove('filled');
					this.parentElement.parentElement.classList.remove('focused');
				} else {
					this.parentElement.parentElement.classList.add('filled');
				}
			});
		}
		// Placeholder Animation End
	}
	render() {
		return (
			<div className="dez-bnr-inr dez-bnr-inr-md" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
				<div className="container">
					<div className="dez-bnr-inr-entry align-m">
						<div className="find-job-bx">
							<Link to={"/"} className="btn btn-primary">Find land, Sales Houses & Rent</Link>
							<h2>Search Between More Then <br />50,000 Open Lands.</h2>
							<form className="dezPlaceAni">
								<div className="row">
									<div className="col-lg-5 col-md-4 col-sm-12">
										<div className="form-group">
											<Form.Control as="select" custom className="select-btn">
												<option>Select Type</option>
												<option>For sale by owner</option>
												<option>Short selling</option>
												<option>Foreclosure</option>
												<option>Buy home</option>
												<option>Home buyer help</option>
												<option>Relocation</option>
												<option>Rent house</option>
												<option>Real Estate</option>
												<option>Sales</option>
												<option>Supporting</option>
											</Form.Control>
										</div>
									</div>
									<div className="col-lg-5 col-md-4 col-sm-12">
										<div className="form-group">
											<label>Location</label>
											<div className="input-group">
												<input type="text" className="form-control" placeholder="" />
												<div className="input-group-append input-group-append-location-icon">
													<span className="input-group-text text-black mr-2"> <FontAwesomeIcon icon={faLocationDot} /></span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-2 col-md-4 col-sm-12">
										<button type="submit" to="/browse-job" className="btn btn-primary btn-block">SEARCH</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default IndexBanner;