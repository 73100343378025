import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import '../../css/design1.css';
import { useEffect } from 'react';
import {getlayoutRegistrationData} from "../../store/action/LayoutAction";
import  { commonFilter } from "../../utils/Common";

export default function Design1( { data } ) {
    const dispatch = useDispatch();
    const selector = useSelector(state =>state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modellayoutData) => {
        setModelData(modellayoutData)
        setShow(true);
    }
    useEffect(()=>{
            dispatch(getlayoutRegistrationData("SRCLAND00001"));
    },[]);
    useEffect(()=>{
        if(selector?.payload?.data?.message === "Success"){         
           setLayoutData(selector?.payload?.data?.data)
        }  
   },[selector?.payload?.data])      

    const design1 = {
        layout1: [
            {
                itemno: 'item1',
                box_number: 1,
                boxsize_t: '49.9"',
                boxsize_r: '36.3"',
                boxsize_l: '28.6"',
                boxsize_b: '39.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 1) : 0 ,
            },
            {
                itemno: 'item2',
                box_number: 2,
                boxsize_r: '36.3"',
                boxsize_b: '39.0"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 2) : 0 ,
            },
            {
                itemno: 'item3',
                box_number: 3,
                boxsize_r: '40.0"',
                boxsize_b: '32.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 3) : 0 ,

            },
            {
                itemno: 'item4',
                box_number: 4,
                boxsize_r: '36.3"',
                boxsize_b: '29.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 4) : 0 ,
            },
            {
                itemno: 'item5',
                box_number: 5,
                boxsize_r: '12.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 5) : 0 ,
            },
        ],
        layout2: [
            {
                itemno: 'item6',
                box_number: 6,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 6) : 0 ,
            },
            {
                itemno: 'item7',
                box_number: 7,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 7) : 0 ,
            },
            {
                itemno: 'item8',
                box_number: 8,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 8) : 0 ,
            },
            {
                itemno: 'item9',
                box_number: 9,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 9) : 0 ,
            },
            {
                itemno: 'item10',
                box_number: 10,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 10) : 0 ,
            },
            {
                itemno: 'item11',
                box_number: 11,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 11) : 0 ,
            },

        ],
        layout3: [
            {
                itemno: 'item12',
                box_number: 12,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 12) : 0 ,
            },
            {
                itemno: 'item13',
                box_number: 13,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 13) : 0 ,
            },
            {
                itemno: 'item14',
                box_number: 14,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 14) : 0 ,
            },
            {
                itemno: 'item15',
                box_number: 15,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 15) : 0 ,
            },
            {
                itemno: 'item16',
                box_number: 16,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 16) : 0 ,
            },
            {
                itemno: 'item17',
                box_number: 17,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 17) : 0 ,
            },
            {
                itemno: 'item18',
                box_number: 18,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 18) : 0 ,
            },
            {
                itemno: 'item19',
                box_number: 19,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 19) : 0 ,
            },
            {
                itemno: 'item20',
                box_number: 20,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 20) : 0 ,
            },
            {
                itemno: 'item21',
                box_number: 21,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 21) : 0 ,
            },
            {
                itemno: 'item22',
                box_number: 22,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 22) : 0 ,
            },
            {
                itemno: 'item23',
                box_number: 23,
                boxsize_t: '40.0"',
                boxsize_b: '34.6"',
                boxsize_l: '35.0"',
                status : layoutData.length > 0 ? commonFilter(layoutData, 23) : 0 ,
            },
        ],
    }

    console.log(`data - ${ JSON.stringify(design1) }`)

    return (
        <>
            {
                data === "true" && <Modal
                show={show}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
        >   
            <Modal.Header closeButton>
                <Modal.Title>Land Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Site Identification No: {modelData?.box_number} <br />
                Site Size Details:{modelData?.boxsize_t} {modelData?.boxsize_r} {modelData?.boxsize_b} {modelData?.boxsize_l}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </Modal>
            }
            <div className="container">
                <div className="">
                    <div className='design-1'>
                        <div className='main_layout'>
                            <div className='layout_1'>
                                {
                                    design1.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_r ? "boxsize_r" : ""}> {data.boxsize_r ? data.boxsize_r : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>

                            <div className='layout_2'>
                                {
                                    design1.layout2.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className="roadcross1"></div>

                            <div className='layout_3'>
                                {
                                    design1.layout3.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross3"></div>
                                <div className="roadcross2"></div>
                            </div>

                            <div className='road_1'>
                                <div className='left'>7.2 M. ROAD </div>
                                <div className='right'>7.2 M. ROAD </div>
                            </div>
                            <div className='road_2'>
                                10.0 M. ROAD
                            </div>
                            <div className='road_3'>
                                7.2 M. ROAD
                            </div>
                            <div className='whitebox1'>
                            </div>
                            <div className='whitebox2'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
