export const USER_LOADED                             = "USER_LOADED"
export const AUTH_ERROR                              = "AUTH_ERROR"     
export const LOGIN_SUCCESS                           = "LOGIN_SUCCESS" 
export const OTP_VERIFIY                             = "OTP_VERIFIY"
export const OTP_VERIFIY_FAIL                        = "OTP_VERIFIY_FAIL"
export const LOGIN_FAIL                              = "LOGIN_FAIL"
export const CLEAR_PROFILE                           = "CLEAR_PROFILE" 
export const LOGOUT                                  = "LOGOUT"
export const REGISTER_SUCCESS                        = "REGISTER_SUCCESS"
export const REGISTER_FAIL                           = "REGISTER_FAIL"
export const BIND_TABLE_COUNTRY                      = "BIND_TABLE_COUNTRY"
export const BIND_TABLE_STATE                        = "BIND_TABLE_STATE"
export const BIND_TABLE_CITY                         = "BIND_TABLE_CITY"
export const BIND_TABLE_GENDER                       = "BIND_TABLE_GENDER"
export const BIND_TABLE_JOB_TYPE                     = "BIND_TABLE_JOB_TYPE"
export const BIND_TABLE_JOB_CATEGORY                 = "BIND_TABLE_JOB_CATEGORY"
export const BIND_TABLE_EXPERIENCE_YEAR              = "BIND_TABLE_EXPERIENCE_YEAR"
export const BIND_TABLE_ERROR                        = "BIND_TABLE_ERROR"
export const EMPLOYE_PROFILE_UPDATE                  = "EMPLOYE_PROFILE_UPDATE"
export const EMPLOYE_PROFILE_UPDATE_SUCCESS          = "EMPLOYE_PROFILE_UPDATE_SUCCESS"
export const EMPLOYE_PROFILE_UPDATE_FAIL             = "EMPLOYE_PROFILE_UPDATE_FAIL"
export const EMPLOYER_PROFILE_UPDATE                 = "EMPLOYER_PROFILE_UPDATE"
export const EMPLOYER_PROFILE_UPDATE_SUCCESS         = "EMPLOYER_PROFILE_UPDATE_SUCCESS"
export const EMPLOYER_PROFILE_UPDATE_FAIL            = "EMPLOYER_PROFILE_UPDATE_FAIL"
export const JOB_POST_INSERT                         = "JOB_POST_INSERT"                     
export const JOB_POST_UPDATE                         = "JOB_POST_UPDATE"
export const JOB_POST_VIEW                           = "JOB_POST_VIEW "
export const JOB_POST_FAIL                           = "JOB_POST_FAIL"
export const JOB_APPLY_VIEW                          = "JOB_APPLY_VIEW "
export const JOB_APPLY_FAIL                          = "JOB_APPLY_FAIL"
export const JOB_APPLY_UPDATE                        = "JOB_APPLY_UPDATE"
export const FEEDS_INSERT                            = "FEEDS_INSERT"
export const FEEDS_VIEW                              = "FEEDS_VIEW"
export const FEEDS_UPDATE                            = "FEEDS_UPDATE"
export const FEEDS_DELETE                            = "FEEDS_DELETE"
export const FEEDS_FAIL                              = "FEEDS_FAIL"
export const STAFF_SUCCESS                           = "STAFF_SUCCESS"
export const STAFF_FAIL                              = "STAFF_FAIL"
export const STAFF_INSERT                            = "STAFF_INSERT"
export const STAFF_UPDATE                            = "STAFF_UPDATE"
export const STAFF_DELETE                            = "STAFF_DELETE"
export const BIND_SUCCESS                            = "BIND_SUCCESS"
export const BIND_FAIL                               = "BIND_FAIL"
export const BIND_INSERT                             = "BIND_INSERT"
export const BIND_UPDATE                             = "BIND_UPDATE"
export const BIND_DELETE                             = "BIND_DELETE"
export const LAYOUT_SUCCESS                          = "LAYOUT_SUCCESS"
export const LAYOUT_FAIL                             = "LAYOUT_FAIL"
export const LAYOUT_VIEW                             = "LAYOUT_VIEW"
export const LAYOUT_INSERT                           = "LAYOUT_INSERT"
export const LAYOUT_UPDATE                           = "LAYOUT_UPDATE"
export const LAYOUT_DELETE                           = "LAYOUT_DELETE"



