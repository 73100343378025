import React from 'react';
import BottomNavbar from './BottomNavbar';
import NavPage from './NavPage';
import TopNavBar from './TopNavBar';

export default function MainContent() {
    return (
        <section className="LandownerPage-wrapper">
            <section className='' >
                <div>
                    <TopNavBar />
                </div>
            </section>
            <section className='Maincontent-navpage'>
                <div className='row'>
                    <div className="col-12">
                        <NavPage />
                    </div>
                </div>
            </section>
            <section className='maincontent-bottompage'>
                <div className='row'>
                    <BottomNavbar />
                </div>
            </section>
        </section>
    )
}
