import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
var bnr7 = require('./../../images/banner/bnr7.png');
var carasoul1 = require('./../../images/banner/carasoul-bg1.png');
var carasoul2 = require('./../../images/banner/carasoul-bg2.png');
export default function Owlblog1() {
	return (
		<div className='container-fluid' >
			<div className="section-head text-center">
				<h2 className=''>What People Say About Us</h2>
			</div>
			<Carousel>
				<Carousel.Item>
					<div class="carousel-item active">
						<div className="container">
							<div className="row">
								<div className="col-lg-2"></div>
								<div className="col-lg-4">
									<div className='image-container-carsoul'>
										<img src={carasoul2} class="d-block img-fluid carasoul-bg-1" alt="Banner-1" width={100} />
										<img src={bnr7} class="d-block img-fluid carasoul-bg-2" alt="Banner-1" width={400} />
										<img src={carasoul1} class="d-block img-fluid carasoul-bg-3" alt="Banner-1" width={100} />
									</div>
								</div>
								<div className="col-lg-4 col-sm-12 carasoul-text">
									<h3 className='mt-6'>Angelie Jolie</h3>
									<p style={{ fontSize: '15px' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur illum cupiditate facilis beatae rerum quaerat. Nobis officiis omnis fugiat eaque beatae excepturi, autem, iure, maiores nostrum quibusdam dolorem tempora ullam!</p>
									<p className='mb-5' style={{ fontSize: '12px' }}>Home seller, Malang</p>
								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
					</div>
				</Carousel.Item  >
				<Carousel.Item>
					<div class="carousel-item active">
						<div className="container">
							<div className="row">
								<div className="col-lg-2"></div>
								<div className="col-lg-4">
									<div className='image-container-carsoul'>
										<img src={carasoul2} class="d-block img-fluid carasoul-bg-1" alt="Banner-1" width={100} />
										<img src={bnr7} class="d-block img-fluid carasoul-bg-2" alt="Banner-1" width={400} />
										<img src={carasoul1} class="d-block img-fluid carasoul-bg-3" alt="Banner-1" width={100} />
									</div>
								</div>
								<div className="col-lg-4 carasoul-text">
									<h3 className='mt-6'>Angelie Jolie</h3>
									<p style={{ fontSize: '15px' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur illum cupiditate facilis beatae rerum quaerat. Nobis officiis omnis fugiat eaque beatae excepturi, autem, iure, maiores nostrum quibusdam dolorem tempora ullam!</p>
									<p className='mb-5' style={{ fontSize: '12px' }}>Home seller, Malang</p>
								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
					</div>
				</Carousel.Item  >
				<Carousel.Item>
					<div class="carousel-item active">
						<div className="container">
							<div className="row">
								<div className="col-lg-2"></div>
								<div className="col-lg-4">
									<div className='image-container-carsoul'>
										<img src={carasoul2} class="d-block img-fluid carasoul-bg-1" alt="Banner-1" width={100} />
										<img src={bnr7} class="d-block img-fluid carasoul-bg-2" alt="Banner-1" width={400} />
										<img src={carasoul1} class="d-block img-fluid carasoul-bg-3" alt="Banner-1" width={100} />
									</div>
								</div>
								<div className="col-lg-4 carasoul-text">
									<h3 className='mt-6'>Angelie Jolie</h3>
									<p style={{ fontSize: '15px' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur illum cupiditate facilis beatae rerum quaerat. Nobis officiis omnis fugiat eaque beatae excepturi, autem, iure, maiores nostrum quibusdam dolorem tempora ullam!</p>
									<p className='mb-5' style={{ fontSize: '12px' }}>Home seller, Malang</p>
								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
					</div>
				</Carousel.Item  >
			</Carousel>
		</div>
	)
}
