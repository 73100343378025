import React from 'react';


export default function Addlistings({ pageData, uploadPage, pageMove }) {

    return (
        <>
            <p className='addlistings1-heading'> {pageMove !== true ? 'View Listing' : 'Add Listings'}</p>
            <div className='d-flex justify-content-center process-container'>
                <div className='text-center processdot1-con'>
                    <span class="processdot1"></span><br />
                    <span>Basic Details</span>
                </div>
                <span className='processline'></span>
                <div className='text-center processdot2-con'>
                    <span class="processdot2"></span><br />
                    <span>Proof Upload</span>
                </div>
            </div>

            <form className='addlistings-form-container'>
                <div class="form-row">
                    <div class="form-group form-group-addlistings col-md-6 pr-md-5">
                        <input type="text" class="form-control border rounded-3" id="streetname" placeholder="Street Name / Plot no" value={pageData.sitestreet !== "" ? pageData.sitestreet : ""} />
                    </div>
                    <div class="form-group col-md-6 pl-md-5">
                        <input type="number" class="form-control border rounded-3" id="numberofplots" placeholder="Sqft" value={pageData.sqft !== "" ? pageData.sqft : ""} />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-md-5">
                        <input type="number" class="form-control border rounded-3" id="contactno" placeholder="Contact Number" value='' />
                    </div>
                    <div class="form-group col-md-6 pl-md-5">
                        <input type="number" class="form-control border rounded-3 " id="whatsppno" placeholder="Whatsapp Number" value='' />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-md-5">
                        <input type="text" class="form-control border rounded-3" id="aadhar" placeholder="Aadhar Number" value='' />
                    </div>
                    <div class="form-group col-md-6 pl-md-5">
                        <input type="number" class="form-control border rounded-3" id="pannumber" placeholder="Pan Number" value='' />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-md-5">
                        <input type="text" class="form-control border rounded-3" id="address1" placeholder="Address Line 1" value={pageData.sitestreet !== "" ? pageData.sitestreet : ""} />
                    </div>
                    <div class="form-group col-md-6 pl-md-5">
                        <input type="text" class="form-control border rounded-3" id="address2" placeholder="Address Line 2" value={pageData.district !== "" ? pageData.district : ""} />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <select class="form-select border border-none" style={{ color: 'grey', fontSize: '15px' }} aria-label=".form-select-sm example">
                            <option selected>Select State</option>
                            <option value="1">....</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <select class="form-select border border-none" style={{ color: 'grey', fontSize: '15px' }} aria-label=".form-select-sm example">
                            <option selected>Select district</option>
                            <option value="1">....</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <input type="number" class="form-control border rounded-3" id="pincode" placeholder="Pincode" value={pageData.sitestreet !== "" ? pageData.sitestreet : ""} />
                    </div>
                </div>
                <div className='d-flex flex-row-reverse my-4'>
                    <button type="submit" onClick={() => uploadPage("upload Image", "Upload Files")} class="btn custombtn-addlistings">Save & Next</button>
                </div>
            </form>
        </>

    )
}

