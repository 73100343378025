import React from 'react';
import { Link } from 'react-router-dom';
var img1 = require('./../../images/city/pic1.jpg');
var img2 = require('./../../images/city/pic2.jpg');
var img3 = require('./../../images/city/pic3.jpg');
var img4 = require('./../../images/city/pic4.jpg');
var img5 = require('./../../images/city/pic5.jpg');
var img6 = require('./../../images/city/pic6.jpg');
var img7 = require('./../../images/city/pic7.jpg');
var img8 = require('./../../images/city/pic8.jpg');

export default function FeatureCities() {
	return (
		<div className="section-full content-inner">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 text-center">
						<p style={{ fontSize: '32px', color: 'black', fontWeight: 'bolder' }}>Featured Cities</p>
						<p style={{ fontSize: '20px', color: 'black' }}>20+ Featured Cities Added Jobs</p>


					</div>
				</div>
				<div className="row">
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<Link to={''}> <img src={img1} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center  pt-2'>City 1</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<Link to={''}> <img src={img2} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center   pt-2'>City 2</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<Link to={''}> <img src={img3} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center  pt-2'>City 3</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<Link to={''}> <img src={img4} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center   pt-2'>City 4</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<Link to={''}> <img src={img5} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center pt-2 '>City 5</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<Link to={''}> <img src={img6} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center pt-2 '>City 6</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<Link to={''}> <img src={img7} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center pt-2 '>City 7</h6>

					</div>
					<div className="col-lg-3 col-sm-12 col-md-6 m-b30">
						<Link to={''}> <img src={img8} alt="" className="city-bx img-fluid mx-auto d-block" />
						</Link>
						<h6 className='text-center pt-2 '>City 8</h6>
					</div>
				</div>
			</div>
		</div >
	)
}
