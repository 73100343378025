import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { OtpVerify , Login } from '../../store/action/LoginAction';
import { Link } from 'react-router-dom';
import frame from '../../images/login/frame.png';
import Header from '../Layout/Header';
import Loader from "../Layout/Loader";

const LoginData = () => {

	const { register, handleSubmit, formState: { errors } } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		registerType: 1,
		otp: "",
		mobileNo: ""
	});
	const { registerType, userName, otp, mobileNo } = formData;
	const [alert, setAlert] = useState({
		type: "",
		color: ""
	});
	const [loader, setLoader] = useState(false);
	const [buttonShow, setButtonShow] = useState(false)
	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
	// Otp Verification
	const onSubmit = async data => {
		await dispatch(Login(mobileNo, otp, registerType, history))
		setButtonShow(false)
	};
	// On Otp Send Submit
	const onOtpSubmit = async data => {
		await dispatch(OtpVerify(mobileNo, registerType));	
		setButtonShow(true);		
	};

	return (
		<>
			<Header />
			{/* { loader && <Loader /> } */}
			<div className="row" style={{ backgroundColor: '#e1e1e1' }}>
				<div className="col-lg-6 col-md-6 text-center login-form-container1" >
					<h4 className='mt-5'>Login To You Now</h4>
					<img src={frame} alt="" className='img-fluid mb-5' />
				</div>
				<div className="col-lg-6 col-md-6 align-self-center login-form-container2">
					<form onSubmit={buttonShow === false ? handleSubmit(onOtpSubmit) : handleSubmit(onSubmit)} className="col-12 login-form px-sm-5 ">
						<p className="font-weight-600">If you have an account with us, please log in.</p>
						<label>Land Owner*</label><input type="radio" name="registerType" id="registerType" value="1" onChange={e => onChange(e)} className="" /> <label>User</label><input type="radio" value="4" onChange={e => onChange(e)} name="registerType" id="registerType" className="" />
						<div className="form-group">
							<label>Mobile Number*</label>
							{errors?.mobileNo && <label className="control-label error-alert" for="inputError">Please Fill Valid Mobile No</label>}
							<div className="input-group">
								<input type="text" className="form-control pb-md-4 pt-md-4 pb-sm-1 pl-sm-1" name="mobileNo" id="mobileNo"  {...register("mobileNo", { required: true, minLength: 10, maxLength: 10, })} value={mobileNo} onChange={e => onChange(e)}
									placeholder="Mobile No" />
								<div className="input-group-append">
									<button className="input-group-text bg-black text-white"> Send Otp </button>
								</div>
							</div>
						</div>
						{
							buttonShow === true && <div className="form-group">
								<label>Otp*</label>
								{errors?.otp && <label className="control-label error-alert" for="inputError">Please Fill Valid Otp</label>}
								<div className="input-group">
									<input type="text" className="form-control pb-md-4 pt-md-4 pb-sm-1 pl-sm-1" name="otp" id="otp"  {...register("otp", { required: true, minLength: 5, maxLength: 5, })} value={otp} onChange={e => onChange(e)}
										placeholder="Otp" />

								</div>
							</div>
						}
						<div className="text-center">
							{buttonShow === true && <button className="btn btn-primary float-left pl-4 pr-4">LOGIN</button>}
							<Link to="/SignUp" className="site-button-link btn-signup forget-pass m-t10 float-right text-black"> Sign up</Link>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default LoginData
