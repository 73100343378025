import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import sqft from '../../images/sqft.png';

const postBlog = [
	{
		image: require("./../../images/city/recent.png"),
	},
	{
		image: require("./../../images/city/recent.png"),
	},
]

export default function RecentListing() {
	return (
		<div className="section-full bg-white content-inner-2">
			<div className="container">
				<div className="row mb-4 text-left">
					<div className="col-12">
						<h3>Recent Listing</h3>
						<h6> Recently Added Properties</h6>
					</div>
				</div>
				<div className="row post-villa-bx">
					<div className="col-lg-12 col-sm-12">
						<ul className="">
							{postBlog.map((item, index) => (
								<li key={index} className=''>
									<div className="row post-villa mb-5">
										<div className="col-lg-2 col-md-4 col-sm-12 post-villa-col">
											<img alt="" src={item.image} width={'100%'} height={'100%'} className='post-villa-image' />
										</div>
										<div className="col-lg-7 col-md-5 col-sm-12">
											<div className="mt-4">
												<h5><Link to={"/"}>Dinoyo Bospom Villa</Link></h5>
												<ul>
													<li className="mb-2"><FontAwesomeIcon icon={faLocationDot} className='pr-3 ml-1 location-icon' /> Sacramento, California</li>
													<li> <img src={sqft} alt="" width={25} height={25} className='pr-2 mb-1 sqft-icon' /> 1629 Sqft</li>
													<li> <p className="site-price mt-2">₹ 270079</p></li>
												</ul>
											</div>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-12 text-center align-self-center">
											<button type="button" class="btn btn-primary">View Plan</button>
										</div>
									</div>
								</li>
							))}

						</ul>
					</div>
				</div>
			</div>
		</div >
	)
}
