import React from 'react';
import facebook from '../../images/footer/facebook.svg';
import insta from '../../images/footer/instagram-brands.svg';
import twitter from '../../images/footer/twitter-brands.svg';

function Footer() {
	return (
		<footer className="site-footer">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
							<div className='logo'>
								<h1>LOGO</h1>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever.</p>
							</div>
							<div >
								<ul className='connect-button'>
									<li className='site_button facebook'> <img src={facebook} alt="facebook" width={12} height={12} /> </li>
									<li className='site_button insta'> <img src={insta} alt="insta" width={20} height={20} /> </li>
									<li className='site_button twitter'> <img src={twitter} alt="twitter" width={20} height={20} /> </li>
								</ul>
							</div>

						</div>
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 row-container1">
							<h5>Community</h5>
							<ul className='footer-ul'>
								<li className='footer-list'>For Rent</li>
								<li className='footer-list'> For Seller</li>
								<li className='footer-list'>For Buyer</li>
								<li className='footer-list'>Search home</li>
							</ul>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-2 col-sm-12 row-container1">
							<h5>About</h5>
							<ul className='footer-ul'>
								<li className='footer-list'> Our Story</li>
								<li className='footer-list'>Careers</li>
								<li className='footer-list'>Meet the team</li>
							</ul>
						</div>
						<div className="col-xl-3  col-lg-3 col-md-3 col-sm-12 row-container1">
							<h5>Contact</h5>
							<ul className='footer-ul'>
								<li className='footer-list'>Sawojajar Club</li>
								<li className='footer-list'>Simple text</li>
								<li className='footer-list'>Malang Jawa timur</li>
								<li className='footer-list'>Indonesia</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-btm">
				<div className="container-footer">
					<div className="row">
						<div className="col-lg-12 text-center">
							<p>© Copyright by Company Name</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;