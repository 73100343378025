import React from 'react'

export default function StaffAdd() {
    return (
        <>
            <p className='addlistings1-heading'>Add Staff</p>
            <form className='addlistings-form-container'>
                <div class="form-row">
                    <select class="form-select" style={{ color: 'grey', fontSize: '15px', margin: '0 5px 30px 5px ' }} >
                        <option selected>Select Role</option>
                        <option value="1">Marketing</option>
                        <option value="2">Office Staff</option>
                    </select>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-md-5">
                        <input type="text" class="form-control" id="username" placeholder="User Name" value='' />
                    </div>
                    <div class="form-group col-md-6 pl-md-5">
                        <input type="number" class="form-control " id="mobile" placeholder="Mobile Number" value='' />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-md-5">
                        <input type="text" class="form-control" id="address1" placeholder="Address Line 1" value='' />
                    </div>
                    <div class="form-group col-md-6 pl-md-5">
                        <input type="text" class="form-control" id="address2" placeholder="Address Line 2" value='' />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <select class="form-select border border-none" style={{ color: 'grey', fontSize: '15px' }} aria-label=".form-select-sm example">
                            <option selected>Select State</option>
                            <option value="1">....</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <select class="form-select border border-none" style={{ color: 'grey', fontSize: '15px' }} aria-label=".form-select-sm example">
                            <option selected>Select district</option>
                            <option value="1">....</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <input type="number" class="form-control border rounded-3" id="pincode" placeholder="Pincode" value='' />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 pr-md-5">
                        <input type="text" class="form-control" id="address1" placeholder="Enter Password" value='' />
                    </div>
                    <div class="form-group col-md-6 pl-md-5">
                        <input type="text" class="form-control" id="address2" placeholder="Confrim Password" value='' />
                    </div>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <button type="submit" class="btn custombtn-staff-addlistings">Save & Next</button>
                </div>
            </form>
        </>

    )
}
