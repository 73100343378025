export const Url = "https://www.sreeragu.com/realestate/api/";
export const ImageUrl = "https://www.sreeragu.com/";
//Filter Data
export const commonFilter =(filterData, row)=>{

     let dataFlter = 0;

     if (filterData.length > 0){
         filterData.map((data,index)=>{
               if(data.siteNumber == row){
                    console.log(`data status  - ${ data?.saleStatus }`)
                    dataFlter =  data?.saleStatus
               }  
          });

          return dataFlter;

     }  else{
          return "null"
     }


}
//Local Storage  - Token
export function saveTokenInLocalStorage(tokenDetails) {   
     localStorage.setItem('token',tokenDetails);
}
//Local Storage - User
export const userLoginData =(memberData)=>{  
     localStorage.setItem("user",JSON.stringify(memberData))
}
// Local Storage User Get
export const getTokenLocalUserStorage =()=>{     
     return JSON.parse(localStorage.getItem('user'));
}
// Local Storage Get
export const getTokenLocalStorage =()=>{     
     return localStorage.getItem('token');
}
//export Local Storage Remove
export const localStorageRemove = () =>{
          localStorage.removeItem("token")
          localStorage.removeItem("user")
     return "Success"
}
export const formatDate = (value) =>{
     let date = new Date(value);
     const day = date.toLocaleString('default', { day: '2-digit' });
     const month = date.toLocaleString('default', { month: 'short' });
     const year = date.toLocaleString('default', { year: 'numeric' });
     return day + '-' + month + '-' + year;
 }
 export const setStatus = (memberData) =>{

     let data = localStorage.getItem('SelectStatus');      
     if(data !== null){         
          if(data?.length > 0){    
               localStorage.removeItem("SelectStatus")              
               let arrays = [];              
               arrays.push(memberData)
               arrays.push(...JSON.parse(data))
               localStorage.setItem("SelectStatus",JSON.stringify(arrays)) 
          }

     }else{
          let arr   = [memberData]         
          localStorage.setItem("SelectStatus",JSON.stringify(arr))
     } 
 }

 export const getStatusLocalData = () =>{
     let data =  localStorage.getItem('SelectStatus');
     return data;

 }