import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

// Images & icons
import addowners from '../../../images/Dashboard/addowner.png';
import approveicon from '../../../images/Dashboard/approve.png';
import arrowright from '../../../images/Dashboard/arrowright.png';
import filtericon from '../../../images/Dashboard/filtericon.png';
import unapproveicon from '../../../images/Dashboard/unapprove.png';


export default function ListingData({ addListing, viewListingData }) {

    const [show, setShow] = useState(false);

    const listings = [
        {

            sitestreet: 'Vasanth',
            district: 'Erode',
            sqft: '9715065892',
            amount: '#HDUJDSJ25',
            date: '14 Apr 2022',
            time: 'at 8:00 PM',
        },
        {
            sitestreet: 'Kumar',
            district: 'Erode',
            sqft: '9715065892',
            amount: '#HDUJDSJ26',
            date: '05 Apr 2022',
            time: 'at 7:00 PM',
        },
        {
            sitestreet: 'Karthik',
            district: 'Salem',
            sqft: '8715065892',
            amount: '#HDUJDSJ27',
            date: '02 Apr 2022',
            time: 'at 10:00 PM',
        },
        {
            sitestreet: 'Sasi',
            district: 'Salem',
            sqft: '6715065892',
            amount: 'HDUJDSJ28',
            date: '02 Apr 2022',
            time: 'at 6:00 PM',
        },
        {
            sitestreet: 'Muthu',
            district: 'Madhurai',
            sqft: '3715065892',
            amount: 'HDUJDSJ29',
            date: '30 Mar 2022',
            time: 'at 9:00 PM',
        },
        {
            sitestreet: 'Mari',
            district: 'Erode',
            sqft: '2715065892',
            amount: '#HDUJDSJ30',
            date: '20 Mar 2022',
            time: 'at 9:00 PM',
        },
        {
            sitestreet: 'Nandhu',
            district: 'Salem',
            sqft: '85011715065892',
            amount: '#HDUJDSJ31',
            date: '24 Mar 2022',
            time: 'at 8:00 PM',
        },
        {
            sitestreet: 'Sri',
            district: 'Chennai',
            sqft: '7715065892',
            amount: '#HDUJDSJ32',
            date: '10 Mar 2022',
            time: 'at 7:00 AM',
        },

    ]
    return (
        <>
            <span className='listings-heading'>Land Owners</span>
            <div className='d-flex flex-wrap justify-content-between mt-4 ml-lg-5 ml-md-1 mr-lg-5'>
                <div class="form-group has-search-listings">
                    <span class="fa fa-search form-control-listings"></span>
                    <input type="text" class="form-control" placeholder="Search anything on Transactions" />
                </div>
                <div>
                    <button className='btn addlistings-cus-btn mr-lg-5 mr-md-5 mr-sm-5 font-weight-bold' onClick={() => addListing(true, "Add List")}> <img src={addowners} alt="" className='mx-2' width={20} /> <span style={{ fontWeight: 'bolder' }}>Add Owners</span> </button>
                    <button className='btn filters-cus-btn'> <img src={filtericon} alt="" className='mx-2' /> <span style={{ fontWeight: 'bolder' }}>Filters</span></button>
                </div>
            </div>
            <div className='table-listings'>
                <table class="table-responsive">
                    <thead>
                        <tr className='table-listings-heading'>
                            <th scope="col">Name</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">ID</th>
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                            <th scope="col">View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listings.map((item, index) => (<tr key={index}>
                            <td>
                                <span className='sitestreet-listings'> {item.sitestreet}</span><br />
                                <span className='district-listings'>{item.district} </span>
                            </td>
                            <td>
                                <span className='sqft-listings'>{item.sqft}</span>
                            </td>
                            <td>
                                <span className='amount-listings'> {item.amount} </span>
                            </td>
                            <td>
                                <span className='date-listings' > {item.date} </span><br />
                                <span className='time-listings'> {item.time} </span>
                            </td>
                            <td>
                                <button className='btn custombtn-action-listings' onClick={() => setShow(true)} >Action</button>
                                <Modal
                                    show={show}
                                    onHide={() => setShow(false)}
                                    dialogClassName="popoup-model"
                                    data-keyboard="false" data-backdrop="static"
                                >
                                    <Modal.Header closeButton className='popup-model-header'></Modal.Header>
                                    <Modal.Body>
                                        <button className='btn'> <img src={approveicon} alt="approveicon" /> Approve</button>
                                        <button className='btn'> <img src={unapproveicon} alt="unapproveicon" />Unapprove</button>
                                    </Modal.Body>
                                </Modal>

                            </td>
                            <td>
                                <button className='btn custombtn-view-listings' onClick={() => viewListingData(item)}>View</button>
                            </td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
            <div className='d-flex flex-row-reverse next-btn-container mt-lg-3'>
                <button className='btn next-cus-btn'>Next <img src={arrowright} alt="" /></button>
            </div>
        </>
    )
}

