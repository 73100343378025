import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getBindData } from '../../../store/action/BindAction';
import addowners from '../../../images/Dashboard/addowner.png';
import arrowright from '../../../images/Dashboard/arrowright.png';
import filtericon from '../../../images/Dashboard/filtericon.png';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import Modal from 'react-bootstrap/Modal';
import { useEffect } from 'react';

const Staffpage =  ({ addStaff, viewStaffData }) => {
    
    const dispatch = useDispatch();
    const selector = useSelector(state =>state?.BindReducer);
    const [districtData, setDistrictData] = useState([])

    useEffect(()=>{
        dispatch(getBindData("District"))
    },[]);

    useEffect(()=>{
         if(selector?.payload?.data?.message === "Success"){
            console.log(`response -${ JSON.stringify(selector?.payload?.data?.data) }`)
            setDistrictData(selector?.payload?.data?.data)
         }       

    },[selector?.payload?.data])   
    return (
        <>
            <div className='p-lg-5'>
                <span className='listings-heading'>District</span>
                <div className='d-flex flex-wrap justify-content-between mt-4 ml-lg-5 ml-md-1 mr-lg-5'>
                    <div class="form-group has-search-listings">
                        <span class="fa fa-search form-control-listings"></span>
                        <input type="text" class="form-control" placeholder="Search anything on Transactions" />
                    </div>
                    <div>
                        <button className='btn addlistings-cus-btn mr-lg-5 mr-md-5 mr-5 font-weight-bold' onClick={() => addStaff(true, "Add List")}> <img src={addowners} alt="" className='mx-2' /> <span style={{ fontWeight: 'bolder' }}>Add Staff</span> </button>
                        <button className='btn filters-cus-btn'> <img src={filtericon} alt="" className='mx-2' /> <span style={{ fontWeight: 'bolder' }}>Filters</span></button>

                    </div>
                </div>

                <div className='table-listings'>
                    <table class="table-responsive">
                        <thead>
                            <tr className='table-listings-heading'>
                                <th scope="col">State Name</th>
                                <th scope="col">State Code</th>
                                <th scope="col">Date Time</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {districtData?.length > 0 && districtData?.map((data, index) => (<tr key={index}>
                                
                                <td>
                                    <span className='sqft-listings'>{data.stateName}</span>
                                </td>
                                <td>
                                    <span className='sqft-listings'>{data.districtName}</span>
                                </td>
                                <td>
                                    <span className='amount-listings'> {data.insertedDateTime} </span>
                                </td>
                                                         
                                <td>
                                    <button className='btn custombtn-view-listings' onClick={() => viewStaffData(data)}>View</button>
                                </td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex flex-row-reverse next-btn-container mt-lg-3'>
                    <button className='btn next-cus-btn'>Next <img src={arrowright} alt="" /></button>
                </div>
            </div>

        </>
    )
}

export default Staffpage

