import React, { useState, useEffect } from "react";
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { getlayoutRegistrationData } from '../../store/action/LayoutAction';
import '../../css/design5.css'
import { commonFilter } from '../../utils/Common';


export default function Design5( { data } ) {
    const dispatch = useDispatch();
    const selector = useSelector(state =>state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modellayoutData) => {
        setModelData(modellayoutData)
        setShow(true);
    }
    useEffect(()=>{
            dispatch(getlayoutRegistrationData("SRCLAND00005"));
    },[]);
    useEffect(()=>{
        if(selector?.payload?.data?.message === "Success"){         
           setLayoutData(selector?.payload?.data?.data)
        }  
   },[selector?.payload?.data])  

    const design5 = {
        layout1: [
            {
                itemno: 'item1',
                box_number: '1',
                boxsize_t: '1.5+9.2"',
                boxsize_b: '"',
                boxsize_l: '16.8+1.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 1) : 0 ,

            },
            {
                itemno: 'item2',
                box_number: '2',
                boxsize_t: '',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '18.3',
                status : layoutData.length > 0 ? commonFilter(layoutData, 2) : 0 ,

            },
            {
                itemno: 'item3',
                box_number: '3',
                boxsize_t: '11.8+1.5',
                boxsize_r: '16.8+1.5',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 3) : 0 ,

            },
            {
                itemno: 'item4',
                box_number: '4',
                boxsize_t: '',
                boxsize_r: '10.9',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 4) : 0 ,

            },
            {
                itemno: 'item5',
                box_number: '5',
                boxsize_t: '20.1',
                boxsize_r: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 5) : 0 ,

            },
        ],
        layout2: [
            {
                itemno: 'item6',
                box_number: '6',
                boxsize_t: '1.5+9.2',
                boxsize_b: '"',
                boxsize_l: '16.8+1.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 6) : 0 ,

            },
            {
                itemno: 'item7',
                box_number: '7',
                boxsize_t: '',
                boxsize_b: '10.7',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 7) : 0 ,

            },
            {
                itemno: 'item8',
                box_number: '8',
                boxsize_t: '10.7',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 8) : 0 ,

            },
            {
                itemno: 'item9',
                box_number: '9',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '18.3',
                status : layoutData.length > 0 ? commonFilter(layoutData, 9) : 0 ,

            },
            {
                itemno: 'item10',
                box_number: '10',
                boxsize_t: '',
                boxsize_r: '13.7',
                boxsize_b: '1.5+9.2',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 10) : 0 ,

            },
            {
                itemno: 'item11',
                box_number: '11',
                boxsize_t: '',
                boxsize_b: '10.7',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 11) : 0 ,

            },
            {
                itemno: 'item12',
                box_number: '12',
                boxsize_t: '',
                boxsize_b: '4.3',
                boxsize_l: '13.7',
                status : layoutData.length > 0 ? commonFilter(layoutData, 12) : 0 ,

            },
            {
                itemno: 'item13',
                box_number: '13',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 13) : 0 ,

            },
            {
                itemno: 'item14',
                box_number: '14',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_l: '13.7',
                status : layoutData.length > 0 ? commonFilter(layoutData, 14) : 0 ,

            },
            {
                itemno: 'item15',
                box_number: '15',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 15) : 0 ,

            },
            {
                itemno: 'item16',
                box_number: '16',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '13.7',
                status : layoutData.length > 0 ? commonFilter(layoutData, 16) : 0 ,
            },
            
        ],
        layout3: [
            {
                itemno: 'item17',
                box_number: '17',
                boxsize_t: '1.5+9.2',
                boxsize_b: '',
                boxsize_r: '18.3',
                boxsize_l: '16.8+1.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 17) : 0 ,

            },
            {
                itemno: 'item18',
                box_number: '18',
                boxsize_t: '',
                boxsize_r: '18.3',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 18) : 0 ,
            },
            {
                itemno: 'item19',
                box_number: '19',
                boxsize_t: '9.2+1.5',
                boxsize_r: '16.8+1.5',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 19) : 0 ,
            },
            {
                itemno: 'item20',
                box_number: '20',
                boxsize_t: '13.7',
                boxsize_b: '9',
                boxsize_l: '5.0',
                status : layoutData.length > 0 ? commonFilter(layoutData, 20) : 0 ,
            },
            {
                itemno: 'item21',
                box_number: '25',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '5.0',
                status : layoutData.length > 0 ? commonFilter(layoutData, 21) : 0 ,
            },
            {
                itemno: 'item22',
                box_number: '21',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 22) : 0 ,
            },
            {
                itemno: 'item23',
                box_number: '26',
                boxsize_t: '13.7',
                boxsize_b: '15',
                boxsize_l: '4.8',
                status : layoutData.length > 0 ? commonFilter(layoutData, 23) : 0 ,
            },
            {
                itemno: 'item24',
                box_number: '22',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 24) : 0 ,
            },
            {
                itemno: 'item25',
                box_number: '24',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 25) : 0 ,
            },
            {
                itemno: 'item26',
                box_number: '23',
                boxsize_t: '13.7',
                boxsize_b: '',
                boxsize_l: '5.0',
                status : layoutData.length > 0 ? commonFilter(layoutData, 26) : 0 ,
            },
            {
                itemno: 'item27',
                box_number: '27',
                boxsize_t: '',
                boxsize_b: '18.3',
                boxsize_r: '10.7',
                status : layoutData.length > 0 ? commonFilter(layoutData, 27) : 0 ,
            },
            {
                itemno: 'item28',
                box_number: '28',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '10.3',
                status : layoutData.length > 0 ? commonFilter(layoutData, 28) : 0 ,
            },
            {
                itemno: 'item29',
                box_number: '29',
                boxsize_t: '18.3',
                boxsize_b: '',
                boxsize_r: '10.1',
                status : layoutData.length > 0 ? commonFilter(layoutData, 29) : 0 ,
            },
            {
                itemno: 'item30',
                box_number: '30',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '10.1',
                status : layoutData.length > 0 ? commonFilter(layoutData, 30) : 0 ,
            },
            {
                itemno: 'item31',
                box_number: '31',
                boxsize_t: '18.3',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 31) : 0 ,
            },
        ],
        layout4: [
            {
                itemno: 'item32',
                box_number: '32',
                boxsize_t: '9.5+1.5',
                boxsize_b: '',
                boxsize_r: '16.8+1.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 32) : 0 ,
            },
            {
                itemno: 'item33',
                box_number: '33',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '18.3',
                status : layoutData.length > 0 ? commonFilter(layoutData, 33) : 0 ,
            },
            {
                itemno: 'item34',
                box_number: '34',
                boxsize_t: '22.4',
                boxsize_b: '',
                boxsize_r: '10.7',
                status : layoutData.length > 0 ? commonFilter(layoutData, 34) : 0 ,
            },
            {
                itemno: 'item35',
                box_number: '35',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 35) : 0 ,
            },
            {
                itemno: 'item36',
                box_number: '36',
                boxsize_t: '15.5',
                boxsize_b: '',
                boxsize_r: '13.2',
                status : layoutData.length > 0 ? commonFilter(layoutData, 36) : 0 ,
            },
        ],
        layout5: [
            {
                itemno: 'item37',
                box_number: '37',
                boxsize_t: '1.5+9.2',
                boxsize_b: '',
                boxsize_l: '16.6+1.5',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 37) : 0 ,
            },
            {
                itemno: 'item38',
                box_number: '38',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '18.1',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 38) : 0 ,
            },
            {
                itemno: 'item39',
                box_number: '39',
                boxsize_t: '9.2+1.5',
                boxsize_r: '16.6+1.5',
                boxsize_l: '18.1',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 39) : 0 ,
            },
            {
                itemno: 'item40',
                box_number: '40',
                boxsize_t: '',
                boxsize_b: '13.7',
                boxsize_l: '10.7',
                status : layoutData.length > 0 ? commonFilter(layoutData, 40) : 0 ,  
            },
            {
                itemno: 'item41',
                box_number: '41',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '9.6',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 41) : 0 ,  
            },
            {
                itemno: 'item42',
                box_number: '42',
                boxsize_t: '',
                boxsize_b: '13.7',
                boxsize_l: '10.5',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 42) : 0 ,  
            },
            {
                itemno: 'item43',
                box_number: '43',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 43) : 0 ,   
            },
            {
                itemno: 'item44',
                box_number: '44',
                boxsize_t: '',
                boxsize_b: '1.5+12.2',
                boxsize_l: '1.5+9.2', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 44) : 0 ,   
            },
            {
                itemno: 'item45',
                box_number: '45',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '10.7', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 45) : 0 ,   
            },
            {
                itemno: 'item46',
                box_number: '46',
                boxsize_t: '',
                boxsize_b: '18.3',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 46) : 0 ,   
            },
            {
                itemno: 'item47',
                box_number: '47',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '10.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 47) : 0 ,    
            },
            {
                itemno: 'item48',
                box_number: '48',
                boxsize_t: '',
                boxsize_b: '18.3',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 48) : 0 ,   
            },
            {
                itemno: 'item49',
                box_number: '49',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '1.5+9.2', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 49) : 0 ,   
            },
        ],
        layout6: [
            {
                itemno: 'item50',
                box_number: '50',
                boxsize_t: '1.5+9.2',
                boxsize_b: '',
                boxsize_l: '19.8+1.5',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 50) : 0 ,  
            },
            {
                itemno: 'item51',
                box_number: '51',
                boxsize_t: '',
                boxsize_b: '21.3',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 51) : 0 , 
            },
            {
                itemno: 'item52',
                box_number: '52',
                boxsize_t: '9.2+1.5',
                boxsize_b: '',
                boxsize_r: '19.8+1.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 52) : 0 ,   
            },
            {
                itemno: 'item53',
                box_number: '53',
                boxsize_t: '13.7',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 53) : 0 ,  
            },
            {
                itemno: 'item54',
                box_number: '54',
                boxsize_t: '18.3',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 54) : 0 , 
            },
        ],
        layout7: [
            {
                itemno: 'item55',
                box_number: '55',
                boxsize_t: '1.5+11.8',
                boxsize_b: '',
                boxsize_l: '9.2+1.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 55) : 0 ,   
            },
            {
                itemno: 'item56',
                box_number: '56',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '10.7',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 56) : 0 , 
            },
            {
                itemno: 'item57',
                box_number: '57',
                boxsize_t: '15.5',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 57) : 0 ,  
            },
        ],
        layout8: [
            {
                itemno: 'item58',
                box_number: '58',
                boxsize_t: '1.5+8.5',
                boxsize_b: '',
                boxsize_l: '15.3+1.5', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 58) : 0 ,  
            },
            {
                itemno: 'item59',
                box_number: '59',
                boxsize_t: '',
                boxsize_b: '9.8',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 59) : 0 , 
            },
            {
                itemno: 'item60',
                box_number: '60',
                boxsize_t: '9.8',
                boxsize_b: '',
                boxsize_l: '16.8', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 60) : 0 ,  
            },
            {
                itemno: 'item61',
                box_number: '61',
                boxsize_t: '',
                boxsize_b: '9.8',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 61) : 0 ,  
            },
            {
                itemno: 'item62',
                box_number: '62',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '16.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 62) : 0 ,  
            },
            {
                itemno: 'item63',
                box_number: '63',
                boxsize_t: '',
                boxsize_b: '1.5+8.5',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 63) : 0 ,   
            },
            {
                itemno: 'item64',
                box_number: '64',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_l: '13.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 64) : 0 ,  
            },
            {
                itemno: 'item65',
                box_number: '65',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 65) : 0 ,  
            },
            {
                itemno: 'item66',
                box_number: '66',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_l: '13.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 66) : 0 ,  
            },
            {
                itemno: 'item67',
                box_number: '67',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 67) : 0 ,  
            },
            {
                itemno: 'item68',
                box_number: '68',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_l: '13.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 68) : 0 ,  
            },
            {
                itemno: 'item69',
                box_number: '69',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 69) : 0 ,    
            },
            {
                itemno: 'item70',
                box_number: '70',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_l: '13.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 70) : 0 ,  
            },
            {
                itemno: 'item71',
                box_number: '71',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '13.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 71) : 0 ,  
            },
            {
                itemno: 'item72',
                box_number: '72',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 72) : 0 ,  
            },
        ],
        layout9: [
            {
                itemno: 'item73',
                box_number: '73',
                boxsize_t: '',
                boxsize_b: '1.5+9.2',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 73) : 0 ,  
            },
            {
                itemno: 'item74',
                box_number: '74',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '12.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 74) : 0 ,  
            },
            {
                itemno: 'item75',
                box_number: '75',
                boxsize_t: '9.1',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 75) : 0 ,   
            },
            {
                itemno: 'item76',
                box_number: '76',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '12.8', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 76) : 0 ,   
            },
            {
                itemno: 'item77',
                box_number: '77',
                boxsize_t: '',
                boxsize_b: '10.0',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 77) : 0 ,  
            },
            {
                itemno: 'item78',
                box_number: '78',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '12.8', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 78) : 0 ,   
            },
            {
                itemno: 'item79',
                box_number: '79',
                boxsize_t: '',
                boxsize_b: '9.1',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 79) : 0 ,  
            },
            {
                itemno: 'item80',
                box_number: '80',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '19.0',
                status : layoutData.length > 0 ? commonFilter(layoutData, 80) : 0 ,    
            },
            {
                itemno: 'item81',
                box_number: '81',
                boxsize_t: '11.0',
                boxsize_r: '9.3',
                boxsize_b: '10.7',
                boxsize_l: '14.5', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 81) : 0 ,   
            },
        ],
        layout10: [
            {
                itemno: 'item82',
                box_number: '82',
                boxsize_t: '16.8+1.5',
                boxsize_b: '',
                boxsize_r: '9.2+1.5', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 82) : 0 ,   
            },
            {
                itemno: 'item83',
                box_number: '83',
                boxsize_t: '18.3',
                boxsize_b: '',
                boxsize_r: '9.8',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 83) : 0 ,  
            },
            {
                itemno: 'item84',
                box_number: '84',
                boxsize_t: '18.3',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 84) : 0 ,   
            },
            {
                itemno: 'item85',
                box_number: '85',
                boxsize_t: '18.3',
                boxsize_b: '16.8+1.5',
                boxsize_r: '1.5+9.2',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 85) : 0 ,  
            },
            {
                itemno: 'item86',
                box_number: '86',
                boxsize_t: '10.7',
                boxsize_b: '',
                boxsize_r: '9.2+1.5',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 86) : 0 ,  
            },
            {
                itemno: 'item87',
                box_number: '87',
                boxsize_t: '',
                boxsize_b: '10.7',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 87) : 0 ,  
            },
            {
                itemno: 'item88',
                box_number: '88',
                boxsize_t: '10.5',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 88) : 0 ,  
            },
            {
                itemno: 'item89',
                box_number: '89',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '18.3',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 89) : 0 ,  
            },
            {
                itemno: 'item90',
                box_number: '90',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 90) : 0 ,  
            },
            {
                itemno: 'item91',
                box_number: '91',
                boxsize_t: '10.7',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 91) : 0 ,  
            },
            {
                itemno: 'item92',
                box_number: '92',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 92) : 0 , 
            },
            {
                itemno: 'item93',
                box_number: '93',
                boxsize_t: '9.1',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 93) : 0 , 
            },
            {
                itemno: 'item94',
                box_number: '94',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 94) : 0 , 
            },
        ],
        layout11: [
            {
                itemno: 'item95',
                box_number: '95',
                boxsize_t: '8.9+1.5',
                boxsize_b: '',
                boxsize_r: '15.3+1.5', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 95) : 0 ,  
            },
            {
                itemno: 'item96',
                box_number: '96',
                boxsize_t: '',
                boxsize_b: '16.8',
                boxsize_r: '10.7', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 96) : 0 ,  
            },
            {
                itemno: 'item97',
                box_number: '97',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '10.7', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 97) : 0 ,  
            },
            {
                itemno: 'item98',
                box_number: '98',
                boxsize_t: '16.8',
                boxsize_b: '',
                boxsize_r: '10.7',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 98) : 0 , 
            },
            {
                itemno: 'item99',
                box_number: '99',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '10.7',
                status : layoutData.length > 0 ? commonFilter(layoutData, 99) : 0 ,   
            },
            {
                itemno: 'item100',
                box_number: '100',
                boxsize_t: '16.8',
                boxsize_b: '',
                boxsize_r: '10.7',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 100) : 0 , 
            },
            {
                itemno: 'item101',
                box_number: '101',
                boxsize_t: '16.8',
                boxsize_r: '1.5+9.2', 
                boxsize_b: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 101) : 0 , 
                 
            },
            {
                itemno: 'item102',
                box_number: '102',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '16.8', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 102) : 0 , 
            },
            {
                itemno: 'item103',
                box_number: '103',
                boxsize_t: '1.5+8.9',
                boxsize_b: '',
                boxsize_l: '15.3+1.5', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 103) : 0 ,  
            },
            {
                itemno: 'item104',
                box_number: '104',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '4.6',
                status : layoutData.length > 0 ? commonFilter(layoutData, 104) : 0 ,   
            },
            {
                itemno: 'item105',
                box_number: '105',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 105) : 0 , 
            },
            {
                itemno: 'item106',
                box_number: '106',
                boxsize_t: '4.6',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 106) : 0 ,  
            },
            {
                itemno: 'item107',
                box_number: '107',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 107) : 0 , 
            },
            {
                itemno: 'item108',
                box_number: '108',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 108) : 0 ,  
            },
            {
                itemno: 'item109',
                box_number: '109',
                boxsize_t: '13.7',
                boxsize_b: '',
                boxsize_l: '4.6', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 109) : 0 ,  
            },
            {
                itemno: 'item110',
                box_number: '110',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 110) : 0 ,  
            },
            {
                itemno: 'item111',
                box_number: '111',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 111) : 0 , 
            },
            {
                itemno: 'item112',
                box_number: '112',
                boxsize_t: '13.7',
                boxsize_b: '',
                boxsize_l: '4.6', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 112) : 0 ,  
            },
            {
                itemno: 'item113',
                box_number: '113',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '"', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 113) : 0 ,  
            },
            {
                itemno: 'item114',
                box_number: '114',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 114) : 0 , 
            },
            {
                itemno: 'item115',
                box_number: '115',
                boxsize_t: '13.7',
                boxsize_b: '',
                boxsize_l: '4.6', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 115) : 0 ,  
            },
            {
                itemno: 'item116',
                box_number: '116',
                boxsize_t: '',
                boxsize_b: '1.5+12.2',
                boxsize_l: '1.5+7.6', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 116) : 0 ,  
            },
        ],
        layout12: [
            {
                itemno: 'item117',
                box_number: '117',
                boxsize_t: '18.3+1.5',
                boxsize_b: '',
                boxsize_r: '7.6+1.5',
                status : layoutData.length > 0 ? commonFilter(layoutData, 117) : 0 ,   
            },
            {
                itemno: 'item118',
                box_number: '118',
                boxsize_t: '19.8',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 118) : 0 , 
            },
            {
                itemno: 'item119',
                box_number: '119',
                boxsize_t: '19.8',
                boxsize_b: '18.3+1.5',
                boxsize_r: '1.5+7.7',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 119) : 0 , 
            },
            {
                itemno: 'item120',
                box_number: '120',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '15.3',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 120) : 0 , 
            },
            {
                itemno: 'item121',
                box_number: '121',
                boxsize_t: '10.7',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 121) : 0 ,  
            },
            {
                itemno: 'item122',
                box_number: '122',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '15.3', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 122) : 0 ,  
            },
            {
                itemno: 'item123',
                box_number: '123',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '12.2', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 123) : 0 ,  
            },
            {
                itemno: 'item124',
                box_number: '124',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 124) : 0 , 
            },
            {
                itemno: 'item125',
                box_number: '125',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 125) : 0 , 
            },
            {
                itemno: 'item126',
                box_number: '126',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_r: '12.2',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 126) : 0 , 
            },
            {
                itemno: 'item127',
                box_number: '127',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 127) : 0 ,  
            },
            {
                itemno: 'item128',
                box_number: '128',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_r: '12.2',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 128) : 0 , 
            },
            {
                itemno: 'item129',
                box_number: '129',
                boxsize_t: '',
                boxsize_b: '4.6',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 129) : 0 ,   
            },
        ],
        layout13: [
            {
                itemno: 'item130',
                box_number: '130',
                boxsize_t: '',
                boxsize_b: '20.6',
                boxsize_l: '',
                status : layoutData.length > 0 ? commonFilter(layoutData, 130) : 0 ,   
            },
            {
                itemno: 'item131',
                box_number: '131',
                boxsize_t: '',
                boxsize_b: '19.1+1.5',
                boxsize_r: '1.5+9.2', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 131) : 0 , 
            },
            {
                itemno: 'item132',
                box_number: '132',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 132) : 0 ,
            },
            {
                itemno: 'item133',
                box_number: '133',
                boxsize_t: '24.2',
                boxsize_b: '21.3',
                boxsize_l: '0.2', 
                boxsize_r: '11.5', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 133) : 0 , 
            },
        ],
        layout14: [
            {
                itemno: 'item134',
                box_number: '134',
                boxsize_t: '',
                boxsize_b: '',
                boxsize_l: '', 
                boxsize_r: '15.6', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 134) : 0 ,
            },
            {
                itemno: 'item135',
                box_number: '135',
                boxsize_t: '',
                boxsize_b: '10.7',
                boxsize_l: '', 
                status : layoutData.length > 0 ? commonFilter(layoutData, 135) : 0 , 
            },
            {
                itemno: 'item136',
                box_number: '136',
                boxsize_t: '',
                boxsize_b: '13.2+1.5',
                boxsize_l: '',  
                status : layoutData.length > 0 ? commonFilter(layoutData, 136) : 0 ,
            },
        ]
        
    }

    return(
        <>
            {
                data === "true" && <Modal
                show={show}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
        >   
            <Modal.Header closeButton>
                <Modal.Title>Land Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Site Identification No: {modelData?.box_number} <br />
                Site Size Details:{modelData?.boxsize_t} {modelData?.boxsize_r} {modelData?.boxsize_b} {modelData?.boxsize_l}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </Modal>
            }  

                 <div className="container">
                <div className="">
                    <div className='design-5'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design5.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div>  
                            <div className='layout-2'>
                                {
                                    design5.layout2.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-3'>
                                {
                                    design5.layout3.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-4'>
                                {
                                    design5.layout4.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-5'>
                                {
                                    design5.layout5.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-6'>
                                {
                                    design5.layout6.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-7'>
                                {
                                    design5.layout7.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-8'>
                                {
                                    design5.layout8.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-9'>
                                {
                                    design5.layout9.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-10'>
                                {
                                    design5.layout10.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-11'>
                                {
                                    design5.layout11.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-12'>
                                {
                                    design5.layout12.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-13'>
                                {
                                    design5.layout13.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            <div className='layout-14'>
                                {
                                    design5.layout14.map((data, index) => {
                                        return (<>
                                           <button key={index} className={`${data.itemno} ${ data.status == 1 ? 'background-color-success' :  'background-color-fail' } design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxsize_t ? "boxsize_t" : ""}> {data.boxsize_t ? data.boxsize_t : ""}</div>
                                                <div className={data.boxsize_b ? "boxsize_b" : ""}> {data.boxsize_b ? data.boxsize_b : ""}</div>
                                                <div className={data.boxsize_l ? "boxsize_l" : ""}> {data.boxsize_l ? data.boxsize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div class="roadcross1"></div> */}
                            </div> 
                            

                            <div class="road-1">23 Feet Panchyat Road </div>
                            <div class="road-2">7.2 M Layout Road </div>
                            <div class="road-3">9 M Layout Road </div>
                            <div class="road-4">10 M Layout Road </div>
                            <div class="road-5">7.2 M Layout Road </div>
                            <div class="road-6">9 M Layout Road </div>
                            <div class="road-7">7.2 M Layout Road </div>
                            <div class="road-8">7.2 M Layout Road </div>
                            <div class="road-9">7.2 M Layout Road </div>
                            <div class="road-10">7.2 M Layout Road </div>
                            <div class="road-11">9 M Layout Road </div>
                            <div class="road-12">9 M Layout Road </div>
                            <div class="road-13">7.2 M Layout Road </div>
                            <div class="road-14">7.2 M Layout Road </div>
                            <div class="road-15">30 Feet Panchyat Road </div>
                            <div class="road-16">10 M Thombalayam - Karamadai Road </div>

                            <div class="whitebox1"></div>
                            <div class="whitebox2"></div>
                            <div class="whitebox3"></div>
                            <div class="whitebox4"></div>
                            <div class="whitebox5"></div>
                            <div class="whitebox6"></div>
                            <div class="whitebox7"></div>
                            <div class="whitebox8"></div>
                            <div class="whitebox9"></div>
                            <div class="whitebox10"></div>
                            <div class="whitebox11"></div>
                            <div class="whitebox12"></div>
                            <div class="whitebox13"></div>
                            <div class="whitebox14"></div>
                            {/* <div class="whitebox15"></div> */}
                            <div class="whitebox16"></div>
                            <div class="whitebox17"></div>

                            <div class="sf1">PARK-1</div>                           
                            <div class="sf2">PARK-2</div>                           
                            <div class="sm1">24.2M</div>                           
                            <div class="sm2">34.2M</div>                           
                            <div class="sm3">12.8M</div>                           
                            <div class="sm4">52.0M</div>                           
                            <div class="sm5">56.2M</div>                           
                            <div class="sm6">50.6M</div>                           
                            <div class="sm7">13.6M</div>                           
                            <div class="sm8">26.6M</div>                           
                            <div class="sm9">23.0M</div>                           
                            <div class="sm10">47.8M</div>                           
                            <div class="sm11">12.6M</div>                           
                            <div class="sm12">99.0M</div>                           
                            <div class="sm13">76.0M</div>                           
                            <div class="sm14">49.4M</div>                           
                            <div class="sm15">85.8M</div>                           
                            <div class="sm16">50.6M</div>                           
                            <div class="sm17">77.2M</div>  


                            <div class="rs1">R.S NO:466</div>                           
                            <div class="rs2">R.S NO:470/1</div>                           
                            <div class="rs3">R.S NO:470/2B</div>                           
                            <div class="rs4">R.S NO:469</div>                           
                            <div class="rs5">R.S NO:474</div>                           
                            <div class="rs6">R.S NO:479</div>                           
                            <div class="rs7">R.S NO:473/2</div>                           
                            <div class="rs8">R.S NO:480</div>                           
                            <div class="rs9">R.S NO:472</div>                           
                            <div class="rs10">R.S NO:471</div>                           
                            <div class="rs11">R.S NO:463</div>                           
                        </div>
                    </div>
                </div>
            </div>    
        </>
    )
}